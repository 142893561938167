import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addDeliveryLocations,
  getDeliveryLocations,
} from "./../store/actions/DeliveryLocationActions";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { getLatLng, geocodeByPlaceId } from "react-google-places-autocomplete";
import Map from "./../components/Map";
import { DeliveryLocationModalContext } from "./../contexts/deliverylocation";

const DeliveryLocationModal = ({
  addDeliveryLocations,
  getDeliveryLocations,
}) => {
  // States
  const [shop_title, setShopTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [lng, setLng] = useState(36.817223);
  const [lat, setLat] = useState(-1.286389);
  const [value, setValue] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(false);

  // Contexts
  const { showModal: open, toggleModal: toggle } = useContext(
    DeliveryLocationModalContext
  );

  // Functions
  const addLocation = async () => {
    try {
      if (shop_title === "")
        return toast.error("Please select delevery location from the map !", {
          autoClose: 10000,
        });

      let obj = {
        latitude: lat.toString(),
        longitude: lng.toString(),
        shop_title,
        default_location: defaultLocation === true ? 1 : 0,
      };
      setLoading(true);
      let result = await addDeliveryLocations(obj);
      if (result) {
        setLoading(false);
        await getDeliveryLocations();
      }
      setValue(null);
      setShopTitle("");
      toast.success("Delivery Location Added Successfully !", {
        autoClose: 10000,
      });

      toggle();
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={toggle}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-80" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-4xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              <form
                className="flex flex-col justify-center p-6 w-full space-y-4"
                onSubmit={async (e) => {
                  e.preventDefault();

                  addLocation();
                }}
              >
                <h1 className="text-3xl text-left font-bold mb-3">Please provide a delivery location</h1>
                <div className="flex flex-col w-full space-y-2">
                  <h2 className="text-md text-gray-600 font-bold mb-2">
                    Delivery Location
                  </h2>
                  <GooglePlacesAutocomplete
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["ke"],
                      },
                    }}
                    apiKey="AIzaSyBw-W7Y4TWh3XOG6k7wWElqS92soODzxJ8"
                    apiOptions={{
                      language: "en",
                      region: "ke",
                    }}
                    selectProps={{
                      value,
                      onChange: async (x) => {
                        try {
                          setValue(x);
                          const {
                            label,
                            value: { place_id },
                          } = x;
                          setShopTitle(label);
                          let result = await geocodeByPlaceId(place_id);
                          if (result && result.length > 0) {
                            let data = result[0];
                            let location = await getLatLng(data);
                            setLat(location?.lat);
                            setLng(location?.lng);
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      },
                    }}
                  />
                </div>

                <div className="flex flex-col w-full space-y-2">
                  <h2 className="text-md text-gray-600 font-bold mb-2">
                    Locations
                  </h2>

                  <div className="h-[250px] overflow-hidden w-full">
                    <Map setLng={setLng} setLat={setLat} lat={lat} lng={lng} />
                  </div>
                </div>

                <div className="flex flex-row w-full items-center space-x-3">
                  <h2 className="text-md text-gray-600 font-bold">
                    Set as default delivery location ?
                  </h2>
                  <input
                    className="w-4 h-4 border-2 border-blue-500 rounded-sm bg-white"
                    type={"checkbox"}
                    name="default"
                    onChange={(e) => setDefaultLocation((prev) => !prev)}
                    value={defaultLocation}
                  />
                </div>

                <div className="w-full flex flex-row items-center justify-end">
                  <button
                    className="bg-tangerine text-white w-fit px-4 py-2 font-bold rounded-md"
                    type="submit"
                  >
                    {loading === true ? "Loading ..." : "Add Location"}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const mapStateToProps = (state) => {
  return {
    delivery_locations: state.delivery_locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addDeliveryLocations, getDeliveryLocations },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryLocationModal);
