import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import instance from "../utils/instance";
import { toast } from "react-toastify";

function ForgotPasswordModal(props) {
  const [msisdn, setMsisdn] = useState("");
  const [showValidationButton, setShowValidationButton] = useState(true);
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [validation_code, setValidationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { isOpen, setIsOpen } = props;

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const fetchPhoneNumber = async () => {
    try {
      setLoading(true);
      if (msisdn === "") return toast.error("Please provide phone number !");
      let response = await instance.get(`users/search/${parseInt(msisdn)}`);
      if (response.status === 200) {
        toast.success(
          "Please enter validation code sent to your phone and email, and fill all fields!", { autoClose: 10000 }
        );
        setShowValidationButton(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Error occurred, Please try again later", { autoClose: 10000 });
    }
  };

  const updatePassword = async () => {
    try {
      setLoading(true);
      if (
        password === "" ||
        confirmPassword === "" ||
        validation_code === "" ||
        msisdn === ""
      ) {
        setLoading(false);
        return toast.error("Please fill all fields !", { autoClose: 10000 });
      }
      if (password !== confirmPassword) {
        setLoading(false);
        return toast.error("Password must match confirm password !", { autoClose: 10000 });
      }
      let obj = {};
      obj.msisdn = msisdn;
      obj.validation_code = validation_code.toUpperCase();
      obj.password = password;
      obj.confirm_password = confirmPassword;
      let response = await instance.patch(`users/password_change`, obj);
      console.log(response);
      toast.success("Password changed successfully !", { autoClose: 10000 });
      setLoading(false);
      props.toggleAuthModal();
      return setIsOpen(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data) {
        toast.error(`${error?.response?.data?.message}`, { autoClose: 10000 });
        return;
      }
      toast.error("Error occurred, Please try again later", { autoClose: 10000 });
      //   setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form
                  className="w-full max-w-md"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await updatePassword();
                  }}
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-md text-gray-600 font-bold mb-2"
                    >
                      Please provide your registered phone number
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="flex flex-col w-full space-y-2">
                        <input
                          type="number"
                          name="msisdn"
                          placeholder="e.g 0712345678"
                          className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0] w-full mb-3"
                          value={msisdn}
                          onChange={(e) => setMsisdn(e.target.value)}
                        />
                      </div>
                      {!showValidationButton && (
                        <div className="flex flex-col w-full space-y-2">
                          <label className="text-md text-gray-600 font-bold mb-2">
                            Validation Code
                          </label>

                          <input
                            type="text"
                            name="validation_code"
                            className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0] w-full mb-3"
                            value={validation_code}
                            onChange={(e) => setValidationCode(e.target.value)}
                          />
                        </div>
                      )}
                      {!showValidationButton && (
                        <div className="flex flex-col w-full space-y-2">
                          <label className="text-md text-gray-600 font-bold mb-2">
                            Password
                          </label>

                          <input
                            className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0] w-full mb-3"
                            type={"password"}
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      )}
                      {!showValidationButton && (
                        <div className="flex flex-col w-full space-y-2">
                          <label className="text-md text-gray-600 font-bold mb-2">
                            Confirm Password
                          </label>

                          <input
                            className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0] w-full mb-3"
                            type={"password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      )}
                    </div>

                    <div className="mt-4 flex flex-row items-center justify-end">
                      {showValidationButton && (
                        <button
                          disabled={loading}
                          type="button"
                          className="bg-tangerine text-white w-full py-2 my-5 font-bold rounded-md"
                          onClick={() => {
                            fetchPhoneNumber();
                          }}
                        >
                          Validate Number
                        </button>
                      )}
                      {!showValidationButton && (
                        <button
                          disabled={loading}
                          type="submit"
                          className="bg-tangerine text-white w-full py-2 my-5 font-bold rounded-md"
                        >
                          Change Password
                        </button>
                      )}
                    </div>
                  </Dialog.Panel>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ForgotPasswordModal;
