import { MOQS, ALL_MOQS, GET_MOQ, FETCH_MORE_MOQS, SEARCH_MOQS, RECENT_MOQS } from './../types/Index';

const initialState = {
    data: [],
    list: [],
    moqs: [],
    moq: null,
    pages: null,
    page: 1,
    next_page: null,
    search_moqs: [],
    recent_moqs: [],
};

export default function CategoryReducer(state = initialState, action) {

    switch(action.type) {

        case MOQS: 

            return {

                ...state,

                data: action.payload,

                list: action.payload.splice(0, 3)

            };

        case ALL_MOQS: 

            return {

                ...state,

                moqs: action.payload.data,

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case FETCH_MORE_MOQS: 

            return {

                ...state,

                moqs: state.moqs.concat(action.payload.data),

                pages: action.payload.pages,

                page: action.payload.page,

                next_page: action.payload.next_page

            };

        case GET_MOQ: 

            return {

                ...state,

                moq: action.payload,

            };
        
        case SEARCH_MOQS:

            return {

                ...state,

                search_moqs: action.payload,

            }
        
        case RECENT_MOQS:
    
            return {
    
                ...state,
    
                recent_moqs: action.payload,
    
            }

        default:

            return state;

    }

};