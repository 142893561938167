import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Modal(props) {
    // Props
    const { isOpen, setIsOpen, children, className = '' } = props

    // Functions
    function closeModal() {
        setIsOpen(false)
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25  backdrop-blur-lg" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-x-hidden p-0">
                        <div className="flex min-h-screen h-fit items-center justify-center md:px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className={`w-full max-w-3xl transform overflow-x-hidden overflow-y-auto rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all ${className}`}>
                                    {children}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
