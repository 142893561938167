import {
    useLocation,
    useParams,
    useNavigate,
  } from 'react-router-dom';
  
  const withRouter = Component => props => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return (
      <Component
        {...props}
        {...{ location, params, navigate }}
      />
    );
  };
  
  export default withRouter;