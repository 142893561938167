import React, { useState } from 'react'
import useDarkMode from '../utils/useDarkMode'
import { DarkModeSwitch } from 'react-toggle-dark-mode'

export default function ThemeSwitcher() {
    const [colorTheme, setTheme] = useDarkMode()
    const [darkMode, setDarkMode] = useState(colorTheme === 'light' ? true : false)

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme)
        setDarkMode(checked)
    }
    return (
        <DarkModeSwitch checked={darkMode} onChange={toggleDarkMode} moonColor='#A68F69' sunColor='sunColor' size={24} />
    )
}
