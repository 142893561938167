import React, { useEffect } from 'react';
import Layout from './../layouts/Main';
import Product from '../components/Product';
import withRouter from '../utils/withRouter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMoqById } from './../store/actions/MoqActions';
import { getReviewsById } from './../store/actions/ReviewActions';
import { getProductById } from './../store/actions/ProductActions';
import { Helmet } from "react-helmet-async";

const MOQ = ({ params, getMoqById, moqs: { moq }, getReviewsById, reviews: { data: reviews }, getProductById, products: { product } }) => {

    useEffect(() => {

        async function fetchMoqById () {

            let result = await getMoqById(params?.id);

            if (result && result?.product_id) {

                await getReviewsById(result?.product_id);

                await getProductById(result?.product_id);

            }

        };

        fetchMoqById();

    }, [getMoqById, getReviewsById, params?.id, getProductById]);

    return (

        <Layout>

            <Helmet prioritizeSeoTags>

                <meta charSet="utf-8" />
                
                <title>{`Litudian ${product  && product?.name ? ` | ${product?.name}` : '' }`}</title>
                <meta name="description" content={`${product?.description}`}></meta>
                <link rel="icon" href="/favicon.ico" />

                <meta property="og:url" content={window.location.href}></meta>
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content={`Litudian ${product && ' | ' + product?.name}`}></meta>
                <meta property="og:description" content={`${product?.description}`}></meta>
                <meta property="og:image" content={product && product?.images && product?.images?.length > 0 ? product?.images[0] : ''}></meta>

                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:domain" content="https://www.litudian.com"></meta>
                <meta property="twitter:url" content={window.location.href}></meta>
                <meta name="twitter:title" content={`Litudian ${product && ' | ' + product?.name}`}></meta>
                <meta name="twitter:description" content={`${product?.description}`}></meta>
                <meta name="twitter:image" content={product && product?.images && product?.images?.length > 0 ? product?.images[0] : ''}></meta>
                <script type="text/javascript">
                {`
                    gtag('event', 'conversion', {'send_to': 'AW-974021229/vrqnCM-PwsYZEO3EudAD'});
                `}
                </script>
        

            </Helmet>
            
            <Product moq={moq} reviews={reviews} product={product} />

        </Layout>

    )

};

const mapStateToProps = (state) => {

    return {

        moqs: state.moqs,

        reviews: state.reviews,

        products: state.products

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({ getMoqById, getReviewsById, getProductById }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MOQ));
