import React, { useContext } from "react";
import Layout from "./../layouts/Main";
import { AuthModalContext } from "./../contexts/auth";

export default function NotAuthenticated() {
  // contexts
  const { toggleModal } = useContext(AuthModalContext);

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center w-full h-[500px] space-y-6 py-5 dark:text-white">
        <h1 className="uppercase text-6xl font-bold text-tangerine">
          Not Authorized
        </h1>
        <p className="text-2xl">Oops !. Please log in to continue</p>
        <button
          className="bg-tangerine text-white w-fit px-4 py-2 font-bold rounded-md"
          onClick={() => toggleModal()}
        >
          Login
        </button>
      </div>
    </Layout>
  );
}
