import React, { createContext, useState } from 'react';

const AuthModalContext = createContext();

const AuthModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <AuthModalContext.Provider
      value={{
        showModal,
        openModal,
        closeModal,
        toggleModal
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};

export { AuthModalContext, AuthModalProvider };