import React, { Component} from 'react';
// import { Link } from 'react-router-dom';
import Layout from './../layouts/Main';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCategories } from './../store/actions/CategoryActions';
import { getMoqs } from './../store/actions/MoqActions';
import { getUserBids } from './../store/actions/BidActions';
import CategoryMOQsCard from './../components/CategoryMOQsCard';
import Bid from '../components/Bid';
import ContentLoader from 'react-content-loader'

const expiry = localStorage.getItem('ltdn_exp');
const currentTime = Date.now() / 1000;
const authenticated = expiry > currentTime;

const BlockLoader = ({ height = 300, radius = 8, uniqueKey = 'unique' }) => (
	<ContentLoader uniqueKey={uniqueKey} speed={2} width={`100%`} height={height} backgroundColor='#ECEBEB' foregroundColor='#b0b0b0'>
		<rect x='0' y='0' rx={`${radius}`} ry={`${radius}`} width='100%' height={`${height}`} />
	</ContentLoader>
)

class Items extends Component {

    constructor(props) {

        super(props);
    
        this.state = {
            
            loading: false

        }

    }

    componentDidMount = async () => {

        this.setState({ loading: true })

        // await this.props.getCategories();

        await this.props.getMoqs();

        if (authenticated) await this.props.getUserBids();

        // await this.props.getUserBids();

        this.setState({ loading: false })

    }

    render() {

        let { categories: { data }, bids: { data: bids }, moqs } = this.props;

        const { loading } = this.state

        // console.log(moqs)

        return (

            <Layout>

                {/* <div className="flex flex-row gap-x-3">

                    <Link to="/items/in-stock" className="bg-litudian-orange text-gray-50 font-bold px-4 rounded-lg text-xs py-1 uppercase">In Stock</Link>

                    <Link to="/items/in-transit" className="bg-litudian-orange text-gray-50 font-bold px-4 rounded-lg text-xs py-1 uppercase">In Transit</Link>

                </div> */}

                <h1 className="text-tangerine font-bold uppercase mt-2">Products</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-5">

                    {loading && <BlockLoader uniqueKey='key2' height={350} radius={6} />}

                    {loading && <BlockLoader uniqueKey='key2' height={350} radius={6} />}

                    {data.splice(0, 2).map((category, i) => <CategoryMOQsCard key={i} category={category} />)}

                </div>
                
            </Layout>

        )

    }

};

const mapStateToProps = (state) => {

    return {

        categories: state.categories,

        moqs: state.moqs,

        bids:  state.bids

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({ getCategories, getMoqs, getUserBids }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
