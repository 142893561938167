import React from "react";

export default function TextInput(props) {
  // Props
  const {
    name,
    label,
    value,
    onChange,
    onBlur,
    error,
    type = "text",
    placeholder = "",
    className = "",
    textarea = false,
  } = props;

  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      <label className="dark:text-[#986510] text-[#986510]">{label}</label>
      <div className="flex flex-col space-y-1">
        {!textarea ? (
          <input
            name={name}
            className="dark:bg-[#222] bg-gray-200 dark:placeholder:text-gray-400/50 placeholder:text-gray-700/40 py-2 px-4 focus:outline-none rounded-lg border dark:border-[#212121]"
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="off"
          />
        ) : (
          <textarea
            name={name}
            className="dark:bg-[#222] bg-gray-200 dark:placeholder:text-gray-400/50 placeholder:text-gray-700/40 py-2 px-4 focus:outline-none rounded-lg border dark:border-[#212121] font-normal"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="off"
            rows={4}
          ></textarea>
        )}
        <span
          className={`text-xs text-red-500 dark:text-red-400 ${
            error ? "block" : "hidden"
          }`}
        >
          {error}
        </span>
      </div>
    </div>
  );
}
