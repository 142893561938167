import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

export default function FAQ_1() {
    return (
        <Accordion preExpanded={["1"]} allowZeroExpanded>
            <AccordionItem uuid="1">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What does minimum order quantity (MOQ) mean?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    MOQ refers to the lowest quantity of a product that a supplier is willing to sell in a single order to a buyer. 
                    For example, if a supplier sets a MOQ of 100 units for a particular product, it means that buyers must purchase 
                    at least 100 units of that product in a single order. Buyers cannot purchase fewer units than the specified MOQ.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How long will my order take to be shipped to Kenya?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        For shippment by sea, the duration is about 60 days (2 months) while shipping by air takes about 21 days or 
                        3 weeks. This time includes the time taken for our suppliers to fullfill MOQ orders made on our platform, and the 
                        time taken for our logisitic partners to ship the product to our warehouses in Nairobi, Kenya.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I check the orders I have made on the Litudian platform?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Once you are logged in, you will navigate to the "My Orders" menu, where you will be able to view 
                        orders that are pending MOQ, paid, unpaid, in transit or orders that have arrived and are ready for 
                        delivery for collection.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Why can't I make my order payments immediately?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Payments for orders are made once an MOQ request is full, i.e. when the orders made match the quantity 
                        requested by the supplier. This ensures that participants are committed to the MOQ order, and also 
                        streamlines our ordering proceses.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        A wrong order was delivered to me. How do I return it and have my correct order delivered?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        If you receive a wrong order during delivery, please contact  our customer service 
                        immediately by calling our phone number, emailing us, or sending a 
                        direct message to any of our social media platforms for immediate resolution.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        I would like to request for a product that is not on your platfrom. How do I request for such an MOQ to be run?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        One of our main menus include an option to "Request for MOQ". Please sign up or log in 
                        to submit a request for a product of your choice to be sourced for you.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What if I have an order that I would like to make for my self, and I am able to fulfill the supplier?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We do have a self jaza (self fill) single source option, where we source the product 
                        from our network of suppliers and ship it to your door step. Please contact us on phone 
                        or email for these kind of requests.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        I need to order exclusive items that are not on your platform. How do I go about this?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We do have a single source option, that also includes making orders for branded/swag items. 
                        Please contact us on phone or email to make these kind of orders. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    );
}
