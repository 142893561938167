import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import { Dialog } from "@headlessui/react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserBids } from "./../store/actions/BidActions";
import { getDeliveryLocations } from "./../store/actions/DeliveryLocationActions";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import instance from "../utils/instance";
import { toTitleCase, truncateText } from "./../utils/helpers";

function DeliveryModal(props) {
  // Props
  let {
    isOpen,
    setIsOpen,
    bids: { data },
    delivery_locations: { data: delivery_locations },
    getDeliveryLocations,
    selectedBids,
    selectItem,
    clearSelectedBids,
  } = props;

  // State
  const [loading, setLoading] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [location, setLocation] = useState("");
  const [items, setItems] = useState([]);

  // useMemo

  // Effects
  useEffect(() => {
    let value = [];
    if (selectedBids?.length > 0) {
      value = data.filter((b) => selectedBids.includes(b?.id));
    }
    setItems(value);
  }, [selectedBids, data]);

  useEffect(() => {
    const fetchDeliveryLocations = async () => {
      try {
        await getDeliveryLocations();
      } catch (error) {
        console.log(error);
      }
    };
    fetchDeliveryLocations();
    return () => {
      setDeliveryFee(null);
      setLocation("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOpen) {
      clearSelectedBids();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const fetchDeliveryFee = async () => {
      try {
        if (
          items.length > 0 &&
          location &&
          location !== "" &&
          location !== "0"
        ) {
          await getDeliveryFee(parseInt(location));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDeliveryFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, location]);

  // Functions
  const getDeliveryFee = async (id) => {
    try {
      let accumulated_weight = getTotalWeight(items);
      setLoading(true);
      let coordinates = delivery_locations?.find((l) => l?.id === id);
      if (!coordinates) return;
      let result = await instance.post("shipping_charges", {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        weight: accumulated_weight,
      });
      if (result?.data?.delivery_fee) {
        setDeliveryFee(result?.data?.delivery_fee);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, {
          autoClose: 10000,
        });
      } else if (error?.response?.data?.error) {
        toast.error(`${error?.response?.data?.error}`, {
          autoClose: 10000,
        });
      } else {
        toast.error(
          "Error occurred while retrieving shipping fee. Please try again later !",
          {
            autoClose: 10000,
          }
        );
      }
    }
  };

  const initiatePayment = async () => {
    try {
      if (!location || location === "") {
        return toast.error("Please select delivery location to proceed !", {
          autoClose: 10000,
        });
      }
      let accumulated_weight = getTotalWeight(items);
      setLoading(true);
      let result = await instance.post("deliveries", {
        delivery_location_id: parseInt(location),
        bid_ids: selectedBids,
        total_weight: accumulated_weight,
      });
      if (result?.data) {
        toast.success("Delivery initiated successfully !", {
          autoClose: 10000,
        });
        setIsOpen(false);
        // Refetching user bids
        await props.getUserBids();
        // Redirecting user to scheduled tab in orders page
        return (window.location.href = `/orders#scheduled`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, {
          autoClose: 10000,
        });
      } else if (error?.response?.data?.error) {
        toast.error(`${error?.response?.data?.error}`, {
          autoClose: 10000,
        });
      } else {
        toast.error(
          "Error occurred while initiating the payment request. Please try again later !",
          {
            autoClose: 10000,
          }
        );
      }
    }
  };

  const getTotalWeight = (data = []) => {
    let accumulated_weight = 0;
    for (const bid of data) {
      if (
        bid?.moq &&
        bid?.moq[0] &&
        bid?.moq[0]?.moq_attributes &&
        bid?.moq[0]?.moq_attributes.length > 0
      ) {
        let bid_attributes = bid?.bid_attributes || [];
        let attributes = bid?.moq[0]?.moq_attributes || [];
        for (const bid_attribute of bid_attributes) {
          let weight =
              attributes.find((a) => a.size_id === bid_attribute.size_id)
                ?.weight ||
              attributes[0]?.weight ||
              0,
            quantity = bid_attribute?.quantity || 0;
          accumulated_weight += weight * quantity;
        }
      }
    }
    return accumulated_weight;
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="space-y-8 max-w-lg relative max-h-full"
    >
      <div className="flex flex-col w-full space-y-8 h-full">
        <div className="flex flex-row items-center justify-between">
          <Dialog.Title
            as="h3"
            className="text-lg font-semibold leading-6 text-tangerine uppercase"
          >
            Delivery Invoice
          </Dialog.Title>
          <button
            className="focus:outline-none"
            onClick={() => setIsOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <p className="font-extrabold italic md:text-left text-center">
          Note: selecting multiple items for delivery results to cheaper
          delivery rates
        </p>
        <div className="flex flex-col space-y-2">
          <p className="text-[#5B5B5B] text-base w-full truncate break-words capitalize font-bold">
            Choose delivery location
          </p>
          <select
            name="location"
            value={location}
            className="focus:outline-none border-[3px] border-[#E0E0E0] bg-transparent rounded-lg py-1"
            onChange={async (e) => {
              setDeliveryFee(null);
              setLocation(e?.target?.value);
              if (
                e?.target?.value !== "" &&
                e?.target?.value !== null &&
                e?.target?.value !== "0"
              )
                await getDeliveryFee(parseInt(e?.target?.value));
            }}
          >
            <option value={null} className="text-gray-500 font-medium px-2">
              Choose...
            </option>
            <option value={0} className="text-gray-500 font-medium px-2">
              Self Pickup
            </option>
            {delivery_locations?.map((location, i) => (
              <option
                key={i}
                value={location?.id}
                className="text-gray-500 font-medium px-2"
              >
                {location?.shop_title}
              </option>
            ))}
          </select>
        </div>
        {items?.length > 0 && (
          <div className="flex flex-col space-y-4">
            {items?.map((bid, i) => (
              <BidItem
                key={i}
                bid={bid}
                selectItem={selectItem}
                selectedBids={selectedBids}
                setIsOpen={setIsOpen}
              />
            ))}
          </div>
        )}
        {selectedBids.length > 0 &&
          (deliveryFee !== null || location === "0") && (
            <div className="flex flex-col space-y-2 flex-1 justify-end">
              {location !== "0" && (
                <p className="text-[#5B5B5B] text-base w-full truncate break-words capitalize font-bold">
                  Approximate Delivery Fee:{" "}
                  <span className="uppercase font-black">
                    KES{" "}
                    {deliveryFee !== null ? deliveryFee.toLocaleString() : 0}
                  </span>
                </p>
              )}

              <button
                className={`px-[10px] py-1 ${
                  loading ? "bg-[#F1592A]/60" : "bg-[#F1592A]"
                } rounded text-white h-fit text-center`}
                onClick={() => initiatePayment()}
              >
                {loading ? "Please wait ..." : "Proceed"}
              </button>
            </div>
          )}
        {loading && (
          <div className="absolute -top-8 -left-0 w-full h-full flex flex-col justify-center items-center bg-gray-800/80">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              strokeColor="#eee"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass="bg-red-500"
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    bids: state.bids,
    delivery_locations: state.delivery_locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserBids, getDeliveryLocations }, dispatch);
};

const BidItem = (props) => {
  // Props
  const { bid, selectItem, selectedBids, setIsOpen } = props;

  // States

  // Memo

  // Functions

  return (
    <div className="grid grid-cols-7 h-fit border border-[#E0E0E0] rounded-[10px] bg-[#d3d3d3] relative">
      <div className="col-span-3 rounded-l-[10px] h-full relative">
        <a href={`/products/${bid?.moq_id}`}>
          <img
            className={`h-[130px] object-cover w-full rounded-l-[10px]`}
            src={
              bid?.product[0]?.images
                ? bid?.product[0]?.images[0]
                : "https://via.placeholder.com/300"
            }
            alt={"product"}
            onError={(e) => {
              e.target.src = "https://via.placeholder.com/300";
            }}
          />
        </a>

        {bid?.order && bid?.order?.length > 0 && bid?.order && (
          <span className="absolute top-5 right-0 text-xs bg-black text-white px-4 py-1">
            {bid?.order[0]?.status}
          </span>
        )}
      </div>

      <div className="col-span-4 h-[130px] max-h-[130px] w-full bg-[#f5f5f5] rounded-r-[10px] flex flex-col p-4 space-y-4">
        {
          <div className="flex flex-col space-y-2">
            <p className="text-[#5B5B5B]  text-base w-full truncate break-words capitalize font-bold">
              {bid?.product
                ? truncateText(toTitleCase(bid?.product[0]?.name), 30)
                : "N/A"}
            </p>
            <p className="text-[#5B5B5B]  text-sm w-full truncate break-words capitalize font-normal italic">
              Number Of Items: {bid?.quantity}
            </p>
          </div>
        }

        <div className="flex flex-col flex-1 space-y-2 justify-end">
          <button
            type="button"
            className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
            onClick={() => {
              selectItem(bid?.id);
              if (selectedBids.length === 1) {
                setIsOpen(false);
              }
            }}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryModal);
