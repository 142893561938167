import React, { Component } from 'react';
import Layout from './../layouts/Main';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Index extends Component {

    constructor(props) {

        super(props);
    
        this.state = {


             
        }

    }

    componentDidMount = async () => {



    }
    
    render() {

        return (

            <Layout>

                <div className="flex flex-col space-y-6 py-5 dark:text-white">

                    <h1 className='uppercase text-2xl font-bold text-tangerine'>Privacy Policy</h1>

                    <p>This privacy notice for Litudian Enterprises ("Company," "we," "us," or "our") describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you, visit our website at http://www.litudian.com or engage with us in other related ways, including any sales, marketing, or events.</p>

                    <p>Reading this privacy notice will help you understand your privacy rights and choices. If you still have any questions or concerns, please get in touch with us at admin@litudian.com.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>WHAT INFORMATION DO WE COLLECT?</h1>

                    <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

                    <p>

                        Personal Information Provided by You. The personal information we collect depends on the context of your interactions with the Services and us, your choices, and the products and features you use. The personal information we collect may include the following:
                        •	names
                        •	phone numbers
                        •	email addresses
                        •	mailing addresses
                        •	usernames
                        •	passwords
                        •	contact preferences
                        •	billing addresses
                        •	debit/credit card numbers
                        •	contact or authentication data

                        Sensitive Information. We do not process sensitive information.

                    </p>

                    <p>Social Media Login Data. We may allow you to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register this way, we will collect the information described in the section "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.</p>

                    <p>All personal information you provide must be true, complete, and accurate, and you must notify us of any changes to such personal information</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>HOW DO WE PROCESS YOUR INFORMATION?</h1>

                    <p>
                        We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                        •	To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
                        •	To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues with the requested service.
                        •	To request feedback. We may process your information when necessary to request feedback and contact you about using our Services.
                        •	To post testimonials. We post testimonials on our Services that may contain personal information.
                        •	To identify usage trends. We may process information about how you use our Services to understand better how they are being used so we can improve them.
                        •	To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.

                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h1>

                    <p>
                        We may need to share your personal information in the following situations:
                        •	Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        •	When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). 
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>

                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Our Cookie Notice sets out specific information about how we use such technologies and how you can refuse certain cookies.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h1>

                    <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider. However, we will often include your name, email address, profile picture, and other information you choose to make public on such a social media platform.</p>

                    <p>We will use the information we receive only for the purposes described in this privacy notice or otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. Therefore, we recommend you review their privacy notice to understand how they collect, use, and share your personal information and how you can set your privacy preferences on their sites and apps.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>HOW LONG DO WE KEEP YOUR INFORMATION?</h1>

                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a more extended retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will need us to keep your personal information for longer than the period users have an account with us.</p>

                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information. Suppose this is impossible (for example, because your personal information has been stored in backup archives). In that case, we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>

                    <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed 100% secure. So we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>

                    <p>Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. Therefore, you should only access the Services within a secure environment</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>WHAT ARE YOUR PRIVACY RIGHTS?</h1>

                    <p>Withdrawing your consent: If we rely on your consent to process your personal information, which may be express and/or implied consent, depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>

                    <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

                    <p>If you would at any time like to review or change the information in your account or terminate your account, you can log in to your account settings and update your user account.</p>

                    <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>

                    <p>Cookies and similar technologies: Most Web browsers accept cookies by default. If you prefer, you can usually choose to set your browser to remove and reject cookies. If you remove or reject cookies, this could affect certain features or services of our Services.</p>

                    <p>If you have questions or comments about your privacy rights, email us at admin@litudian.com.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>

                    <p>
                        If you have questions or comments about this notice, you may email us at admin@litudian.com or by post to:<br/>
                        <address>
                        Litudian Enterprises<br/>
                        Highway Mall, off Bunyala Road, 3rd floor Room 12<br/>
                        Nairobi, Kenya
                        </address>
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h1>

                    <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact admin@litudian.com</p>

                </div>

            </Layout>

        )

    }

};

const mapStateToProps = (state) => {

    return {

        

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({  }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
