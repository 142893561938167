import { RECENT_SEARCHES } from './../types/Index';
import instance from './../../utils/instance';

export const getRecentSearches = () => async dispatch => {

    try {

        const response = await instance.get(`recent_searches`);

        if (response?.data?.recent_searches) {

            dispatch({ type: RECENT_SEARCHES, payload: response?.data?.recent_searches });

        }

    } catch (error) {

        throw error;
        
    }

}