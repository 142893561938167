import { DELIVERY_LOCATIONS } from './../types/Index';

const initialState = {
    data: [],
};

export default function DeliveryLocationReducer(state = initialState, action) {

    switch(action.type) {

        case DELIVERY_LOCATIONS: 

            return {

                ...state,

                data: action.payload,

            };

        default:

            return state;

    }

};