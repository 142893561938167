import React, { Component } from "react";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { showUser, updateUser } from "./../store/actions/UserActions";
import { getDeliveryLocations } from "./../store/actions/DeliveryLocationActions";
import { toast } from "react-toastify";
import instance from "./../utils/instance";
import ChangePasswordModal from "../modals/ChangePassword";
import EditUserDetailsModal from "../modals/EditUserDetailsModal";
import Location from "../components/Location";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ContentLoader from "react-content-loader";
import { DeliveryLocationModalContext } from "./../contexts/deliverylocation";

const BlockLoader = ({
  height = 300,
  width = "100%",
  radius = 8,
  uniqueKey = "unique",
}) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={width}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",

      last_name: "",

      email: "",

      msisdn: "",

      username: "",

      affiliate_code: "",

      openModal: false,

      openChangePasswordModal: false,

      openEditModal: false,

      deliveryLocation: null,

      monthly_affiliate_earnings: [],

      loading: false,
    };

    this.file = React.createRef();
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    let uid = localStorage.getItem("ltdn_uid");

    await this.props.showUser(uid);

    this.setUserDetails();

    await this.props.getDeliveryLocations();

    this.getMonthlyAffliateEarnings();

    this.setState({ loading: false });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  setUserDetails = () => {
    const {
      user: { details },
    } = this.props;

    this.setState({
      first_name: details.first_name,
      last_name: details.last_name,
      email: details.email,
      msisdn: details.msisdn,
      username: details.username,
      affiliate_code: details.affiliate_code,
    });
  };

  changeAvatar = async (e) => {
    try {
      let file = e.target.files[0];

      let formData = new FormData();

      formData.append("file", file);

      const response = await instance.post("upload", formData);

      if (response.data !== "") {
        let obj = { avatar: response.data.file };

        let uid = localStorage.getItem("ltdn_uid");

        await this.props.updateUser(uid, obj);

        toast.success("User Avatar updated successfully !", {
          autoClose: 10000,
        });

        return (window.location.href = "/profile");
      }
    } catch (error) {
      toast.error("Error occurred while updating user avatar !", {
        autoClose: 10000,
      });

      console.log(error);
    }
  };

  update = async () => {
    try {
      const { first_name, last_name, username, email, msisdn } = this.state;

      let obj = { first_name, last_name, username, email, msisdn };

      let uid = localStorage.getItem("ltdn_uid");

      await this.props.updateUser(uid, obj);

      toast.success("Account Details Successfully Updated !", {
        autoClose: 10000,
      });
    } catch (error) {
      toast.error("Error occurred while updating account details !", {
        autoClose: 10000,
      });

      console.log(error);
    }
  };

  showModal = () => this.setState({ openModal: !this.state.openModal });

  toggleChangePasswordModal = () =>
    this.setState({
      openChangePasswordModal: !this.state.openChangePasswordModal,
    });

  toggleEditModal = () =>
    this.setState({
      openEditModal: !this.state.openEditModal,
    });

  getMonthlyAffliateEarnings = async () => {
    try {
      const {
        user: { details },
      } = this.props;
      let code = details.affiliate_code || "";
      // let code = "BPZQCAC";
      let result = await instance.get(`affiliates_tracking/${code}`);
      if (result?.data) {
        this.setState({ monthly_affiliate_earnings: result?.data || [] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      openModal,
      openChangePasswordModal,
      monthly_affiliate_earnings,
      loading,
    } = this.state;

    const {
      user: { details },
      delivery_locations: { data: delivery_locations },
    } = this.props;

    const lituPoints = localStorage.getItem("ltdn_points");
    let earnings = [...monthly_affiliate_earnings].reverse() || [];

    return (
      <DeliveryLocationModalContext.Consumer>
        {({ toggleModal }) => (
          <Layout>
            {loading ? (
              <div className="flex flex-col h-fit w-full mt-5">
                <BlockLoader
                  uniqueKey="profile"
                  height={600}
                  width={"100%"}
                  radius={6}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 py-5 gap-x-12">
                  <div className="flex flex-row md:col-span-full w-full">
                    <p className="text-tangerine uppercase font-extrabold text-lg">
                      My Account
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-6 w-full">
                    <div className="relative">
                      <div className="w-full h-[250px] bg-gray-400 rounded-md flex flex-col justify-center items-center shadow">
                        {details?.avatar && (
                          <img
                            className="h-full object-cover w-full rounded"
                            style={{ objectFit: "cover" }}
                            src={details?.avatar}
                            alt={details?.first_name}
                            onError={(e) => {
                              e.target.src = "/img/avatar.jpg";
                            }}
                          />
                        )}

                        {!details?.avatar && (
                          <h2 className="text-gray-50 uppercase font-bold text-center">
                            Click To Change Photo
                          </h2>
                        )}
                      </div>

                      <button
                        className="absolute -top-3 -right-3 p-3 bg-tangerine text-white rounded-full shadow"
                        onClick={() => this.file.current.click()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="flex flex-row items-center justify-between space-x-4">
                      <button
                        className="w-full py-1 px-1 bg-tangerine text-white rounded-sm"
                        onClick={() => this.toggleEditModal()}
                      >
                        Edit User
                      </button>

                      <button
                        className="w-full py-1 px-1 bg-tangerine text-white rounded-sm"
                        onClick={() => this.toggleChangePasswordModal()}
                      >
                        Change Password
                      </button>
                    </div>

                    <input
                      type="file"
                      name="avatar"
                      ref={this.file}
                      className="hidden"
                      onChange={this.changeAvatar}
                    />
                  </div>

                  <div className="flex flex-col w-full md:col-span-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 gap-y-5 md:gap-y-10">
                      <div className="md:col-span-full">
                        <p className="text-3xl dark:text-white capitalize">{`${
                          details?.first_name || ""
                        } ${details?.last_name || ""}`}</p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">Username</p>
                        <p className="dark:text-white text-lg">
                          {details?.username || "-"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">
                          Affiliate Code
                        </p>
                        <p className="dark:text-white text-lg">
                          {details?.affiliate_code || "-"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">LituPoints</p>
                        <p className="dark:text-white text-lg">
                          {lituPoints ? lituPoints : "N/A"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">Phone</p>
                        <p className="dark:text-white text-lg">
                          {details?.msisdn || "N//A"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">Member Since</p>
                        <p className="dark:text-white text-lg">
                          {moment(details?.date_created).format("DD/MM/YYYY") ||
                            "N/A"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">Email</p>
                        <p className="dark:text-white text-lg">
                          {details?.email || "N/A"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="dark:text-white text-lg">Items Ordered</p>
                        <p className="dark:text-white text-lg">
                          {details?.bids && details?.bids?.length > 0
                            ? details?.bids?.length
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                  <div className="flex flex-row items-center justify-between col-span-full">
                    <p className="text-tangerine uppercase font-extrabold text-lg">
                      Delivery Locations
                    </p>
                    <button
                      className="bg-tangerine text-white text-sm py-1 font-bold rounded-md uppercase px-2 flex flex-row"
                      onClick={() => toggleModal()}
                    >
                      Add
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </button>
                  </div>
                  {delivery_locations !== undefined &&
                    delivery_locations.map((location, i) => (
                      <Location key={i} location={location} />
                    ))}
                  {delivery_locations !== undefined &&
                    delivery_locations?.length === 0 && (
                      <div className="col-span-full flex flex-col justify-center items-center h-[300px] space-y-8">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-10 h-10 text-tangerine"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                          />
                        </svg>

                        <h3 className="text-litudian-orange dark:text-white capitalize">
                          No available locations
                        </h3>
                      </div>
                    )}
                  {earnings?.length > 0 && (
                    <div className="col-span-full flex flex-col w-full space-y-3">
                      <p className="text-tangerine uppercase font-extrabold text-lg">
                        Monthly Affiliates Earnings
                        <h4 className="text-litudian-orange dark:text-white capitalize">
                          Earnings paid out monthly.{" "}
                          <Link to="/terms">Terms of service apply.</Link>
                        </h4>
                      </p>
                      <div className="w-[100%] bg-gray-200 p-6 rounded-md md:h-[400px]">
                        <Bar
                          style={{ width: "100%" }}
                          options={{
                            scales: {
                              y: {
                                beginAtZero: true,
                                title: {
                                  display: true,
                                  text: "Earnings in KES",
                                },
                              },
                              x: {
                                beginAtZero: true,
                                title: {
                                  display: true,
                                  text: "Month of the year (last 12 months)",
                                },
                                offset: true,
                                grid: {
                                  offset: true,
                                },
                                ticks: {
                                  align: "center",
                                },
                                barPercentage: 0.1,
                                categoryPercentage: 0,
                              },
                            },
                            maintainAspectRatio: true,
                            responsive: true,
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (tooltipItem, data) => {
                                    // Access the index of the hovered bar
                                    const index = tooltipItem.dataIndex;
                                    // Access the data and index of the current bar
                                    const earning = earnings[index] || null;

                                    if (earning?.status === 1)
                                      return `${Math.trunc(
                                        earning?.sum
                                      )} (paid out)`;
                                    return `${Math.trunc(
                                      earning?.sum
                                    )} (not paid out)`;
                                  },
                                },
                              },
                              legend: {
                                position: "top",
                                display: false,
                              },
                              title: {
                                display: false,
                                text: "",
                              },
                            },
                          }}
                          data={{
                            labels: [
                              ...earnings?.map(
                                (a) => `${a?.month_created}, ${a?.year_created}`
                              ),
                            ],
                            datasets: [
                              {
                                label: "",
                                data: [...earnings?.map((a) => a?.sum)],
                                backgroundColor: (context) => {
                                  // Access the data and index of the current bar
                                  const value =
                                    earnings[context.dataIndex]?.status;
                                  // Set background color based on your condition (status)
                                  return value === 1 ? "green" : "orange";
                                },
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}

            <ChangePasswordModal
              open={openChangePasswordModal}
              toggle={this.toggleChangePasswordModal}
            />
            <EditUserDetailsModal
              open={this.state.openEditModal}
              toggle={this.toggleEditModal}
            />
          </Layout>
        )}
      </DeliveryLocationModalContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    delivery_locations: state.delivery_locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showUser, updateUser, getDeliveryLocations },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
