import React, { useState } from "react";
import Modal from "../components/Modal";
import { Dialog } from "@headlessui/react";
import { Formik } from "formik";
import Button from "../components/Button";
import { toast } from "react-toastify";
import instance from "../utils/instance";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDeliveryLocations } from "./../store/actions/DeliveryLocationActions";

function Index(props) {
  // Props
  const { isOpen, setIsOpen, location, getDeliveryLocations } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Functions
  const deleteDeliveryLocation = async () => {
    setLoading(true);
    let response = await instance.patch(`delivery_locations/${location?.id}`, {
      status: 0,
    });
    if (response) {
      setLoading(false);
      toast.success("Delivery Location Deleted Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // await getDeliveryLocations();
      setIsOpen(false);
      return (window.location.href = "/profile");
    }
    try {
    } catch (error) {
      setLoading(false);
      toast.error(
        "Could not complete your request at this time. Please try again later !",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="space-y-8 max-w-md">
      <div className="flex flex-row items-center justify-between w-full">
        <Dialog.Title
          as="h3"
          className="text-lg font-bold leading-6 text-gray-900"
        >
          Delete Delivery Location
        </Dialog.Title>
        <button onClick={() => setIsOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          deleteDeliveryLocation();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="mt-4">
              <p>
                Are you sure you want to delete {location?.shop_title} as your
                delivery location ?
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mt-8 space-y-2">
              <Button
                className="w-full flex flex-col items-center justify-center py-3 bg-tangerine hover:bg-red-700"
                type="submit"
              >
                {loading ? "Loading..." : "Delete"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    delivery_locations: state.delivery_locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getDeliveryLocations }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
