import React, { Component } from 'react';
import Layout from './../layouts/Main';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Index extends Component {

    constructor(props) {

        super(props);
    
        this.state = {


             
        }

    }

    componentDidMount = async () => {



    }
    
    render() {

        return (

            <Layout>

                <div className="flex flex-col space-y-6 py-5 dark:text-white">

                    <h1 className='uppercase text-2xl font-bold text-tangerine'>TERMS AND CONDITIONS</h1>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>AGREEMENT TO TERMS</h1>

                    <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Litudian Enterprises ("Company", "we", "us", or "our"), concerning your access to and use of the litudian.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected to it (collectively, the "site"). </p>

                    <p>We are registered in Kenya and have our registered office at Highway Mall, off Bunyala Road, 3rd floor Room 12, Nairobi. You agree that by accessing the site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL THESE TERMS OF USE, YOU ARE EXPRESSLY PROHIBITED FROM USING THE site, AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

                    <p>In our sole discretion, we reserve the right to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Terms of Use, and you waive any right to receive specific notice of each such change</p>

                    <p>Please ensure that you check the applicable Terms every time you use our site to understand which ones apply. You will be subject to and will be deemed to have been made aware of and accepted the changes in any revised Terms of Use by your continued use of the site after the date such revised Terms of Use are posted.</p>

                    <p>The information provided on the site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or subject us to any registration requirement within such jurisdiction or country. </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>INTELLECTUAL PROPERTY RIGHTS</h1>

                    <p>Unless otherwise indicated, the site is our proprietary property. All source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property.</p>

                    <p>The Content and the Marks are provided on the site "AS IS" for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>

                    <p>Provided that you are eligible to use the site, you are granted a limited license to access and use the site and to download or print a copy of any portion of the content to which you have properly gained access solely for your personal, non-commercial use.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>INTELLECTUAL PROPERTY RIGHTS</h1>

                    <p>
                        By using the site, you represent and warrant that:<br/>
                        1.	All registration information you submit will be accurate, current, and complete.<br/>
                        2.	You will maintain the accuracy of such information and promptly update such registration information as necessary.<br/>
                        3.	You have the legal capacity and agree to comply with these Terms of Use.<br/>
                        4.	You will not access the site through automated or non-human means, whether through a bot, script or otherwise.<br/>
                        5.	You will not use the site for any illegal or unauthorized purpose.<br/>
                        6.	Your use of the site will not violate any applicable law or regulation.
                    </p>

                    <p>Suppose you provide any information that is untrue, inaccurate, not current, or incomplete. In that case, we have the right to suspend or terminate your account and refuse any current or future use of the site (or any portion thereof).</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>USER REGISTRATION</h1>

                    <p>You may be required to register with the site. You agree to keep your password confidential and will be responsible for all your account and password use. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>PRODUCTS</h1>

                    <p>We make every effort to display as accurately as possible the colours, features, specifications, and details of the products available on the site. However, we do not guarantee that the products' colours, features, specifications, and details will be accurate, complete, reliable, current, or free of other errors. Your electronic display may not accurately reflect the actual colours and details of the products. All products are subject to availability, and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products at any time for any reason. Prices for all products are subject to change.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>PURCHASES AND PAYMENT</h1>

                    <p>You agree to provide current, complete, and accurate purchase and account information for all purchases via the site. You further agree to promptly update account and payment information, including email address, payment method, so that we can complete your transactions and contact you as needed. Sales tax will be added to the purchase price as we deem required. We may change prices anytime, and all payments shall be in Kenyan Shillings.</p>

                    <p>You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.</p>

                    <p>We reserve the right to refuse any order placed through the site. In our sole discretion, we may limit or cancel quantities purchased per person, household, or order. These restrictions may include orders placed by or under the same customer account, payment method, and/or orders using the same billing or shipping address. In addition, we reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>AFFILIATE PAYOUTS</h1>
                    <p>All affiliates are independent contractors, and not employees of Litudian Enterprises.</p>
                    <p>All affiliate programs are currently only available to Kenyans.</p>
                    <p>Affiliate programs run by Litudian Enterprises earn a commission of three percent (3%) and the commision is only earned on paid orders.</p>
                    <p>Affiliate payouts are only made to a registered phone number via mobile money, on the fifteenth (15th) day of every month.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>RETURN POLICY</h1>

                    <p>All sales are final, and Litudian Enterprises will issue no refund.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>PROHIBITED ACTIVITIES</h1>

                    <p>You may not access or use the site for any purpose other than that we make available. Litudian Enterprises may not use the site in connection with any commercial endeavours except those specifically endorsed or approved by us</p>

                    <p>
                        As a user of the site, you agree not to:<br/>
                        •	Trick, defraud, or mislead other users and us, especially in any attempt to learn sensitive account information such as user passwords.<br/>
                        •	Use any information from the site to harass, abuse, or harm another person.<br/>
                        •	Make improper use of our support services or submit false reports of abuse or misconduct.<br/>
                        •	Upload or transmit viruses, Trojan horses, or other material, including excessive use of capital letters and spamming that interferes with any party's uninterrupted use.<br/>
                        •	Engage in any automated system use, such as scripts to send comments or messages or using any data mining, robots, or similar data gathering and extraction tools.<br/>
                        •	Upload or transmit any material that acts as a passive or active information collection or transmission mechanism, including, without limitation, clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar.<br/>
                        •	Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the site to you.<br/>
                        •	Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any software comprising or making up a part of the site.<br/>
                        •	Use a buying agent or purchasing agent to make purchases on the site.<br/>
                        •	Make any unauthorized use of the site, including collecting users' usernames and/or email addresses by electronic or other means to send unsolicited email or create user accounts by automated means or under false pretences.<br/>
                        •	Use the site as part of any effort to compete with us or use the site and/or the Content for any revenue-generating endeavour or commercial enterprise.<br/>
                        •	Use the site to advertise or offer to sell goods and services.<br/>
                        •	Sell or otherwise transfer your profile.
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>USER-GENERATED CONTRIBUTIONS</h1>

                    <p>
                        The site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality. It may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the site and the Marketplace Offerings and through third-party websites. Any contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:<br/>
                        •	You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the site, and other users of the site to use your Contributions in any manner contemplated by the site and these Terms of Use.<br/>
                        •	You have the written consent, release, and/or permission of every identifiable person in your Contributions to use the name or likeness of each such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the site and these Terms of Use.<br/>
                        •	Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.<br/>
                        •	Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.<br/>
                        •	Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.<br/>
                        •	Your Contributions do not violate any applicable law, regulation, or rule.<br/>
                        •	Your Contributions do not violate any third party's privacy or publicity rights.<br/>
                        •	Your Contributions do not violate any applicable law concerning child pornography or are otherwise intended to protect the health or well-being of minors.<br/>
                        •	Your Contributions do not include any offensive comments connected to race, national origin, gender, sexual preference, or physical handicap.<br/>
                        •	Your Contributions do not otherwise violate or link to material that violates any provision of these Terms of Use or any applicable law or regulation.<br/>
                        Any use of the site or the Marketplace Offerings in violation of the preceding violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the site and the Marketplace Offerings.
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>CONTRIBUTION LICENSE</h1>

                    <p>You automatically grant by posting your Contributions to any part of the site. You represent and warrant that you have the right to grant to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, worldwide right, and license to host, use, copy, reproduce, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, such Contributions  in any media format and through any media channels</p>

                    <p>This license will apply to any form, media, or technology now known or hereafter developed and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>

                    <p>We do not assert any ownership over your Contributions. You retain full ownership of all your Contributions and any intellectual property or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your contributions in any area on the site. You are solely responsible for your Contributions to the site. You expressly agree to exonerate us from any responsibility and to refrain from legal action against us regarding your Contributions.</p>

                    <p>
                        We have the right, in our sole and absolute discretion:<br/>
                        1.	To edit, redact, or otherwise change any Contributions.<br/>
                        2.	Re-categorise any Contributions to place them in more appropriate locations on the site.<br/>
                        3.	To pre-screen or delete any Contributions at any time and for any reason, without notice.<br/>
                        We have no obligation to monitor your Contributions.
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>GUIDELINES FOR REVIEWS</h1>

                    <p>
                        We may provide you with areas on the site to leave reviews or ratings. However, when posting a review, you must comply with the following criteria:<br/>
                        1.	You should have firsthand experience with the person/entity being reviewed.<br/>
                        2.	Your reviews should not contain offensive profanity or abusive, racist, offensive, or hateful language.<br/>
                        3.	Your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability.<br/>
                        4.	Your reviews should not contain references to illegal activity.<br/>
                        5.	You should not be affiliated with competitors if posting negative reviews.<br/>
                        6.	You should not make any conclusions as to the legality of conduct.<br/>
                        7.	You may not post any false or misleading statements.<br/>
                        8.	You may not organize a campaign encouraging others to post positive or negative reviews.
                    </p>

                    <p>
                        We may accept, reject, or remove reviews at our sole discretion. We have absolutely no obligation to screen or delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or claims, liabilities, or losses resulting from any review. By posting a review, you now grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to the review.
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>SITE MANAGEMENT</h1>

                    <p>
                        We reserve the right, but not the obligation, to:<br/>
                        1.	Monitor the site for violations of these Terms of use.<br/>
                        2.	Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including, without limitation, reporting such user to law enforcement authorities.<br/>
                        3.	In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof.<br/>
                        4.	In our sole discretion and without limitation, notice, or liability, to remove from the site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems.<br/>
                        5.	Otherwise, manage the site in a manner designed to protect our rights and property and to facilitate the proper functioning of the site and the Marketplace Offerings.
                    </p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>COPYRIGHT INFRINGEMENTS</h1>

                    <p>We respect the intellectual property rights of others. If you believe that any material available on or through the site infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a "Notification"). Litudian Enterprises will send a copy of your Notification to the person who posted or stored the material addressed in the Notification. Please be advised that under applicable law, you may be liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the site infringes your copyright, you should consider first contacting an attorney.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>TERM AND TERMINATION</h1>

                    <p>These Terms of Use shall remain in full force and effect while you use the site. WITHOUT limiting any other provision of these terms of use, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the site and the marketplace offerings to any person for any reason or no reason, including without limitation for breach of any representation, warranty, or covenant contained in these terms of use or of any applicable law or regulation. we may terminate your use or participation in the site and the marketplace offerings or delete your account and any content or information you posted at any time, without warning, at our sole discretion.</p>

                    <p>Suppose we terminate or suspend your account for any reason. In that case, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the Third Party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including, without limitation, pursuing civil, criminal, and injunctive redress.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>GOVERNING LAW</h1>

                    <p>These terms shall be governed by and defined following the laws of Kenya. Litudian Enterprises and yourself irrevocably consent that the courts of Kenya shall have exclusive jurisdiction to resolve any dispute that may arise with these terms.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>CORRECTIONS</h1>

                    <p>There may be information on the site that contains typographical errors, inaccuracies, or omissions that may relate to the Marketplace Offerings, including descriptions, pricing, availability, and other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the site at any time without prior notice</p>

                </div>

            </Layout>

        )

    }

};

const mapStateToProps = (state) => {

    return {

        

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({  }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
