import React, { useState } from "react";
import Modal from "../components/Modal";
import { Dialog } from "@headlessui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import Label from "../components/Label";
import Input from "../components/Input";
import InputError from "../components/InputError";
import Button from "../components/Button";
import { toast } from "react-toastify";
import instance from "../utils/instance";

const schema = Yup.object().shape({
  product_name: Yup.string()
    .min(3)
    .required("Product Name is a required field"),
  product_link: Yup.string()
    .url("Product link must be a valid URL")
    .required("Product Link is a required field"),
  quantity: Yup.number().required(),
  description: Yup.string(),
  images: Yup.mixed(),
});

function Index(props) {
  // Props
  const { isOpen, setIsOpen } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Functions
  const requestMoq = async (values) => {
    try {
      const { product_name, product_link, quantity, description, images } =
        values;
      let obj = {};
      setLoading(true);
      let imgs = [];
      if (images && images?.length > 0) {
        for (let i = 0; i < images.length; i++) {
          let formData = new FormData();
          formData.append("file", images[i].file);
          const response = await instance.post("upload", formData);
          if (response.data !== "") {
            imgs.push(response.data.file);
          }
        }
      }
      obj = {
        product_name,
        product_link,
        quantity,
        description,
      };
      if (imgs?.length > 0) {
        obj.images = imgs;
      }
      let response = await instance.post("recommendations", obj);
      if (response) {
        setLoading(false);
        toast.success("Request Submitted Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsOpen(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "Could not complete your request at this time. Please try again later !",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const previewFiles = (files) => {
    let data = [];

    for (let i = 0; i < files.length; i++) {
      let uri = URL.createObjectURL(files[i]);

      data.push({
        uri,

        file: files[i],
      });
    }

    return data;
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="space-y-8">
      <div className="flex flex-row items-center justify-between w-full">
        <Dialog.Title
          as="h3"
          className="text-lg font-bold leading-6 text-gray-900"
        >
          Request MOQ Campaign
        </Dialog.Title>
        <button onClick={() => setIsOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          product_name: "",
          product_link: "",
          quantity: "",
          description: "",
          images: [],
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          requestMoq(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              <Label htmlFor="product_name">Product Name</Label>

              <Input
                id="product_name"
                type="text"
                value={values.product_name}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                autoFocus
              />

              <InputError messages={[errors.product_name]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="product_link">Product Link</Label>

              <Input
                id="product_link"
                type="text"
                value={values.product_link}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                autoFocus
              />

              <InputError messages={[errors.product_link]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="quantity">Quantity</Label>

              <Input
                id="quantity"
                type="number"
                value={values.quantity}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                // required
                autoFocus
                min={0}
              />

              <InputError messages={[errors.quantity]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="quantity">Description</Label>

              <textarea
                id="description"
                value={values.description}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3 py-3"
                onChange={handleChange}
                placeholder="(Optional) Describe the product weight, size, colors e.t.c"
              ></textarea>

              <InputError messages={[errors.description]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="quantity">Photo(s)</Label>

              <Input
                id="images"
                type="file"
                accept="image/*"
                multiple
                name="images"
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={(event) => {
                  let data = previewFiles(event.currentTarget.files);
                  setFieldValue("images", [...values?.images, ...data]);
                }}
                // required
                autoFocus
                min={0}
              />

              <InputError messages={[errors.images]} className="mt-2" />

              {values?.images?.length > 0 && (
                <div className="relative grid grid-cols-8">
                  {values.images.map((image, i) => (
                    <div
                      key={i}
                      style={{
                        backgroundImage: `url("${image.uri}")`,
                        backgroundSize: "cover",
                      }}
                      className="border border-gray-400 relative h-[80px] w-[80px] mt-2 mb-2 cursor-pointer"
                      onClick={() => {
                        let images = values.images.filter((img) => {
                          return img.uri !== image.uri;
                        });
                        setFieldValue("images", images);
                      }}
                    >
                      <button
                        type="button"
                        className="bg-red-500 text-white rounded absolute right-1 top-1 p-[2px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center mt-8 space-y-2">
              <Button
                className="w-full flex flex-col items-center justify-center py-3 bg-tangerine"
                type="submit"
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default Index;
