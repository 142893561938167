import axios from 'axios';
import Cookies from 'js-cookie'

const instance = axios.create({

    baseURL: 'https://api.litudian.com/api/v1/',

});

const token = Cookies.get("ltdn") || null;

if (token) {

    // Apply To Every Request
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;

} else {

    // Delete Auth Header
    delete instance.defaults.headers.common['Authorization'];

}

export default instance;