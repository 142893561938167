import React, { Fragment, useState, useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dialog, Transition } from '@headlessui/react'
import { showUser, updateUser } from "./../store/actions/UserActions";
import { toast } from 'react-toastify';

function EditUserDetailsModal(props) {
    const { open, toggle, user } = props;
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [msisdn, setMsisdn] = useState('')
    const [username, setUsername] = useState('')

    useEffect(() => {
        const { details } = user
        setFirstName(details?.first_name || '')
        setLastName(details.last_name || '')
        setEmail(details?.email || '')
        setMsisdn(details.msisdn || '')
        setUsername(details.username || '')
    }, [user])

    const update = async () => {
        try {
    
          let obj = { first_name, last_name, username, email, msisdn };
    
          let uid = localStorage.getItem("ltdn_uid");
    
          await props.updateUser(uid, obj);
    
          toast.success("Account Details Successfully Updated !", { autoClose: 10000 });

          toggle()

        } catch (error) {
          toast.error("Error occurred while updating account details !", { autoClose: 10000 });
    
          console.log(error);
        }
      };

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={toggle}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/90" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    
                                    <form
                                        className="flex flex-col"
                                        onSubmit={(e) => {
                                        e.preventDefault();

                                        update();
                                        }}
                                    >
                                        <div className='flex flex-col py-3'>
                                            <h1 className='text-xl'>Edit User</h1>
                                        </div>

                                        <div className="flex flex-col gap-y-3">

                                        <div className='flex flex-col'>
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>Firstname</h2>
                                            <input
                                                type="text"
                                                name="first_name"
                                                value={first_name}
                                                onChange={(e) => setFirstName(e?.target?.value)}
                                                placeholder="Firstname"
                                                className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0]"
                                            />
                                        </div>

                                        <div className='flex flex-col'>
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>Lastname</h2>
                                            <input
                                                type="text"
                                                name="last_name"
                                                value={last_name}
                                                onChange={(e) => setLastName(e?.target?.value)}
                                                placeholder="Lastname"
                                                className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0]"
                                            />
                                        </div>

                                        <div className='flex flex-col'>
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>Username</h2>
                                            <input
                                                type="text"
                                                name="username"
                                                value={username}
                                                onChange={(e) => setUsername(e?.target?.value)}
                                                placeholder="Username"
                                                className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0]"
                                            />
                                        </div>

                                        <div className='flex flex-col'>
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>Email</h2>
                                            <input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e?.target?.value)}
                                                placeholder="Email"
                                                className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0]"
                                            />
                                        </div>

                                        <div className='flex flex-col'>
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>Phone Number</h2>
                                            <input
                                                type="text"
                                                name="msisdn"
                                                readOnly
                                                value={msisdn}
                                                onChange={(e) => setMsisdn(e?.target?.value)}
                                                placeholder="Mpesa Number"
                                                className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none text-gray-600 bg-gray-200"
                                            />
                                        </div>

                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-tangerine text-white w-full py-2 my-5 font-bold rounded-md"
                                        >
                                            Save
                                        </button>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      user: state.user,
  
      delivery_locations: state.delivery_locations,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      { showUser, updateUser },
      dispatch
    );
  };

  export default connect(mapStateToProps, mapDispatchToProps)(EditUserDetailsModal);
