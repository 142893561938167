import { DELIVERY_LOCATIONS } from "./../types/Index";
import instance from "./../../utils/instance";

export const getDeliveryLocations = () => async (dispatch) => {
  try {
    const response = await instance.get("delivery_locations");
    dispatch({
      type: DELIVERY_LOCATIONS,
      payload: response?.data?.delivery_locations,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const addDeliveryLocations = (data) => async (dispatch) => {
  try {
    const response = await instance.post("delivery_locations", data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const editDeliveryLocations = (id, data) => async (dispatch) => {
  try {
    const response = await instance.patch(`delivery_locations/${id}`, data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
