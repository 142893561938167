const Button = ({ type = "submit", loading = false, className, ...props }) => {
    return (
      <button
        type={type}
        className={`${className} inline-flex items-center px-4 py-2 bg-tangerine border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-[#986510] active:bg-[#986510] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150`}
        {...props}
      >
        {loading ? "Please wait ..." : props.children}
      </button>
    );
};

export default Button;
