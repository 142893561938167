import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import DeleteDeliveryLocation from "../modals/DeleteDeliveryLocation";
import EditDeliveryLocation from "../modals/EditDeliveryLocation";

export default function Location(props) {
  const { location } = props;
  let center = {
    lat: parseFloat(location?.latitude),
    lng: parseFloat(location?.longitude),
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBw-W7Y4TWh3XOG6k7wWElqS92soODzxJ8",
  });
  const [map, setMap] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (!isLoaded) return;

  return (
    <>
      <div className="flex flex-row w-full bg-[#eee] dark:bg-white mt-4 rounded-md">
        <div className="h-[150px] md:h-[200px] w-[150px] md:w-[100%] overflow-hidden rounded-l">
          <GoogleMap
            mapContainerClassName="w-full h-full"
            center={center}
            zoom={2}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker position={center} draggable={false} />
          </GoogleMap>
        </div>

        <div className="flex flex-row px-4 justify-between items-start py-8 gap-y-1 relative w-full">
          <h2 className="text-[#5B5B5B] capitalize font-normal text-lg">
            {location?.shop_title} {location?.default_location === 1 && '(default)'}
          </h2>
          <div className="flex flex-row items-center space-x-3 w-fit h-fit">
            <button
              className="bg-tangerine text-white px-2 py-2 rounded shadow"
              onClick={() => setIsOpenEditModal(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
            <button
              className="bg-tangerine text-white px-2 py-2 rounded shadow"
              onClick={() => setIsOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <DeleteDeliveryLocation
        location={location}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <EditDeliveryLocation
        location={location}
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
      />
    </>
  );
}
