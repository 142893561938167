import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import store from "./store/Index";
import { AuthModalProvider } from "./contexts/auth";
import { DeliveryLocationModalProvider } from "./contexts/deliverylocation";
import ProtectedRoute from "./components/ProtectedRoute";
import { CookiesProvider } from "react-cookie";

// components
import Profile from "./pages/Profile";
import Index from "./pages/Index";
import Account from "./pages/Account";
import Items from "./pages/Items";
import SingleItem from "./pages/Items/Show";
import EditItem from "./pages/Items/Edit";
import ItemInStock from "./pages/ItemInStock";
import ItemInTransit from "./pages/ItemInTransit";
import RequestItem from "./pages/RequestItem";
import Forum from "./pages/Forum";
import Sales from "./pages/Sales";
import Order from "./pages/Order";
import Categories from "./pages/Categories";
import MOQ from "./pages/MOQ";
import Terms from "./pages/Terms";
import Privacy from "./pages/PrivacyPolicy";
import Cookie from "./pages/CookiePolicy";
import Search from "./pages/Search";
import Cart from "./pages/Cart";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import MOQCampaigns from "./pages/MOQCampaigns";
import NotFound from "./pages/404";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <React.StrictMode>
      <CookiesProvider>
        <DeliveryLocationModalProvider>
          <AuthModalProvider>
            <HelmetProvider>
              <Provider store={store}>
                <Router>
                  <ScrollToTop />
                  <Routes>
                    <Route exact path="/" element={<Index />} />
                    <Route element={<ProtectedRoute />}>
                      <Route exact path="/profile" element={<Profile />} />
                      <Route exact path="/orders" element={<Order />} />
                    </Route>
                    <Route exact path="/account" element={<Account />} />
                    <Route exact path="/items" element={<Items />} />
                    <Route exact path="/items/:id" element={<SingleItem />} />
                    <Route
                      exact
                      path="/items/:id/edit"
                      element={<EditItem />}
                    />
                    <Route
                      exact
                      path="/items/in-stock"
                      element={<ItemInStock />}
                    />
                    <Route
                      exact
                      path="/items/in-transit"
                      element={<ItemInTransit />}
                    />
                    <Route
                      exact
                      path="/request-item"
                      element={<RequestItem />}
                    />
                    <Route exact path="/forum" element={<Forum />} />
                    <Route exact path="/sales" element={<Sales />} />
                    <Route
                      exact
                      path="/categories/:id"
                      element={<Categories />}
                    />
                    <Route exact path="/products/:id" element={<MOQ />} />
                    <Route exact path="/terms" element={<Terms />} />
                    <Route exact path="/privacy" element={<Privacy />} />
                    <Route exact path="/cookie-policy" element={<Cookie />} />
                    <Route exact path="/search" element={<Search />} />
                    <Route exact path="/cart" element={<Cart />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/contacts" element={<Contacts />} />
                    <Route exact path="/campaigns" element={<MOQCampaigns />} />
                    <Route exact path="*" element={<NotFound />} />
                  </Routes>
                </Router>
                <ToastContainer />
              </Provider>
            </HelmetProvider>
          </AuthModalProvider>
        </DeliveryLocationModalProvider>
      </CookiesProvider>
    </React.StrictMode>
  );
}

export default App;
