import React, { createContext, useState } from "react";

const DeliveryLocationModalContext = createContext();

const DeliveryLocationModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <DeliveryLocationModalContext.Provider
      value={{
        showModal,
        openModal,
        closeModal,
        toggleModal,
        setShowModal,
      }}
    >
      {children}
    </DeliveryLocationModalContext.Provider>
  );
};

export { DeliveryLocationModalContext, DeliveryLocationModalProvider };
