import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCategories } from "./../store/actions/CategoryActions";
import { getMoqs } from "./../store/actions/MoqActions";
import { getUserBids } from "./../store/actions/BidActions";
import { getDeliveryLocations } from "./../store/actions/DeliveryLocationActions";
import ContentLoader from "react-content-loader";
import ProgressBar from "@ramonak/react-progress-bar";
import { toTitleCase, truncateText } from "./../utils/helpers";
import Countdown from "./../components/CountDown";
import { Link } from "react-router-dom";
import DeliveryModal from "../modals/DeliveryModal";

const expiry = localStorage.getItem("ltdn_exp");
const currentTime = Date.now() / 1000;
const authenticated = expiry > currentTime;

const BlockLoader = ({ height = 300, radius = 8, uniqueKey = "unique" }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={`100%`}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

const BidItem = (props) => {
  // Props
  const { bid, selectedBidItems, selectBidItem } = props;

  // States

  // Memo

  // Functions

  return (
    <div className="grid grid-cols-7 h-fit border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B] relative">
      <div className="col-span-3 rounded-l-[10px] h-[170px] relative">
        <a href={`/products/${bid?.moq_id}`}>
          <img
            className={`${
              bid?.is_collected === 1 ? "h-[170px]" : "h-[170px]"
            }  object-cover w-full rounded-l-[10px]`}
            src={
              bid?.product[0]?.images
                ? bid?.product[0]?.images[0]
                : "https://via.placeholder.com/300"
            }
            alt={"product"}
            onError={(e) => {
              e.target.src = "https://via.placeholder.com/300";
            }}
          />
        </a>

        {bid?.order && bid?.order?.length > 0 && bid?.order && (
          <span className="absolute top-5 right-0 text-xs bg-black text-white px-4 py-1">
            {bid?.order[0]?.status}
          </span>
        )}
      </div>

      <div className="col-span-4 h-full w-full bg-white dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-4">
        {
          <div className="flex flex-col space-y-2">
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              {bid?.product
                ? truncateText(toTitleCase(bid?.product[0]?.name), 30)
                : "N/A"}
            </p>
            <p className="text-[#5B5B5B] dark:text-white text-sm w-full truncate break-words capitalize font-normal italic">
              Number Of Items: {bid?.quantity}
            </p>
          </div>
        }
        {bid?.shipping_status === 1 &&
          bid?.is_collected === 0 &&
          bid?.delivery_initiated === 0 &&
          bid.order[0].delivery_initiated === 1 && (
            <div className="flex flex-row items-center space-x-3">
              <input
                id={`bid_${bid.id}`}
                className="form-checkbox h-5 w-5"
                name="select"
                type="checkbox"
                checked={selectedBidItems.includes(bid.id)}
                onChange={() => selectBidItem(bid.id)}
              />
              <label
                htmlFor={`bid_${bid.id}`}
                className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold"
              >
                Select item for delivery
              </label>
            </div>
          )}

        {bid?.delivery_initiated === 1 && bid.delivery_status === 1 && (
          <div className="flex flex-col flex-1 space-y-2 justify-end">
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Order No:{" "}
              <span className="capitalize font-black">
                {bid?.delivery_order_number}
              </span>
            </p>
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Fee Status:{" "}
              <span className="capitalize font-black">
                {bid.delivery_payment_status === 1 ? "PAID" : "UNPAID"}
              </span>
            </p>
            <button
              type="button"
              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
              onClick={() => (window.location.href = `/items/${bid?.id}`)}
            >
              View Order
            </button>
          </div>
        )}

        {bid?.delivery_initiated === 1 && bid.delivery_status === 2 && (
          <div className="flex flex-col flex-1 space-y-2 justify-end">
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Order No:{" "}
              <span className="capitalize font-black">
                {bid?.delivery_order_number}
              </span>
            </p>
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Fee Status:{" "}
              <span className="capitalize font-black">
                {bid.delivery_payment_status === 1 ? "PAID" : "UNPAID"}
              </span>
            </p>
            <button
              type="button"
              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
              onClick={() => (window.location.href = `/items/${bid?.id}`)}
            >
              View Order
            </button>
          </div>
        )}

        {bid?.delivery_initiated === 1 && bid.delivery_status === 3 && (
          <div className="flex flex-col flex-1 space-y-2 justify-end">
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Order No:{" "}
              <span className="capitalize font-black">
                {bid?.delivery_order_number}
              </span>
            </p>
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Fee Status:{" "}
              <span className="capitalize font-black">
                {bid.delivery_payment_status === 1 ? "PAID" : "UNPAID"}
              </span>
            </p>
            <button
              type="button"
              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
              onClick={() => (window.location.href = `/items/${bid?.id}`)}
            >
              View Order
            </button>
          </div>
        )}

        {bid?.shipping_status === 1 && bid?.is_collected === 1 && (
          <div className="flex flex-col flex-1 space-y-2">
            <p className="text-[#5B5B5B] dark:text-white text-base w-full truncate break-words capitalize font-bold">
              Delivery Fee Status:{" "}
              <span className="capitalize font-black">Not applicable</span>
            </p>
            <button
              type="button"
              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
              onClick={() => (window.location.href = `/items/${bid?.id}`)}
            >
              View Order
            </button>
          </div>
        )}

        {bid?.shipping_status === 0 && (
          <div className="flex flex-col space-y-2 flex-1 justify-end">
            <Link
              to={`/items/${bid?.id}`}
              className="px-[10px] py-1 bg-[#F1592A] rounded text-white h-fit text-center"
            >
              Pay Shipping Fee
            </Link>
          </div>
        )}

        <button
          type="button"
          className="px-[10px] py-1 bg-tangerine rounded text-white h-fit hidden"
          onClick={() => (window.location.href = `/items/${bid?.id}`)}
        >
          View Order
        </button>
      </div>
    </div>
  );
};

class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      section: 0,
      deliverySection: 0,
      showDeliveryModal: false,
      selectedBids: [],
    };
  }

  componentDidMount = async () => {
    if (window.location && window.location.hash) {
      this.setActiveTab(window.location.hash);
    }

    this.setState({ loading: true });

    await this.props.getDeliveryLocations();

    if (authenticated) await this.props.getUserBids();

    this.setState({ loading: false });
  };

  setActiveTab = (tab) => {
    switch (tab) {
      case "#pending":
        this.setState({ section: 0 });
        break;
      case "#unpaid":
        this.setState({ section: 1 });
        break;
      case "#paid":
        this.setState({ section: 2 });
        break;
      case "#transit":
        this.setState({ section: 3 });
        break;
      case "#arrived":
        this.setState({ section: 5 });
        break;
      default:
        this.setState({ section: 0 });
    }
  };

  getTotal(order) {
    let sum = 0;

    if (
      order?.product &&
      order?.product[0]?.varying_sizes === 1 &&
      order?.bid_attributes &&
      order?.moq &&
      order?.moq[0]?.moq_attributes
    ) {
      order?.bid_attributes.map((attr) => {
        let price =
          order?.moq[0]?.moq_attributes?.find(
            (a) => a?.size_id === attr?.size_id
          )?.price || 0;

        let total = price * attr?.quantity;

        sum += total;
      });
    }

    return sum;
  }

  getPercentage(bid) {
    if (bid && bid?.moq && bid?.moq?.length > 0) {
      return Math.trunc(
        (bid?.total_bid_quantities * 100) / bid?.moq[0]?.quantity
      );
    }

    return 0;
  }

  calculateTimeLeft(endDate) {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)) || "00",
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24) || "00",
        minutes: Math.floor((difference / 1000 / 60) % 60) || "00",
        seconds: Math.floor((difference / 1000) % 60) || "00",
      };
    }

    return timeLeft;
  }

  renderSwitch = (section, data = []) => {
    // Props
    let {
      delivery_locations: { data: delivery_locations },
    } = this.props;

    switch (section) {
      case 0:
        data = data.filter(
          (b) =>
            b.delivery_initiated === 0 &&
            b.is_collected === 0 &&
            b.order[0].status ===
              "Goods arrived at Litudian - Ready to collect" &&
            b?.shipping_status === 0
        );
        return (
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
            {data.length > 0 ? (
              data.map((bid, i) => {
                return (
                  <BidItem
                    key={i}
                    bid={bid}
                    delivery_locations={delivery_locations}
                    selectedBidItems={this.state.selectedBids}
                    selectBidItem={this.handleCheckboxChange}
                  />
                );
              })
            ) : (
              <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  No Orders
                </h1>
              </div>
            )}
          </div>
        );
      case 5:
        data = data.filter(
          (b) =>
            b.delivery_initiated === 0 &&
            b.is_collected === 0 &&
            b.order[0].status ===
              "Goods arrived at Litudian - Ready to collect" &&
            b?.shipping_status === 1
        );
        return (
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
            {data.length > 0 ? (
              data.map((bid, i) => {
                return (
                  <BidItem
                    key={i}
                    bid={bid}
                    delivery_locations={delivery_locations}
                    selectedBidItems={this.state.selectedBids}
                    selectBidItem={this.handleCheckboxChange}
                  />
                );
              })
            ) : (
              <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  No Orders
                </h1>
              </div>
            )}
          </div>
        );
      case 1:
        data = data.filter(
          (b) =>
            b.delivery_initiated === 1 &&
            b.is_collected === 0 &&
            b.delivery_status === 1 &&
            b.order[0].status === "Goods arrived at Litudian - Ready to collect"
        );
        return (
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
            {data?.length > 0 ? (
              data.map((bid, i) => {
                return (
                  <BidItem
                    key={i}
                    bid={bid}
                    delivery_locations={delivery_locations}
                  />
                );
              })
            ) : (
              <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  No Orders
                </h1>
              </div>
            )}
          </div>
        );
      case 2:
        data = data.filter(
          (b) =>
            b.delivery_initiated === 1 &&
            b.is_collected === 0 &&
            b.delivery_status === 2 &&
            b.order[0].status === "Goods arrived at Litudian - Ready to collect"
        );
        return (
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
            {data?.length > 0 ? (
              data.map((bid, i) => {
                return (
                  <BidItem
                    key={i}
                    bid={bid}
                    delivery_locations={delivery_locations}
                  />
                );
              })
            ) : (
              <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  No Orders
                </h1>
              </div>
            )}
          </div>
        );
      case 3:
        data = data.filter(
          (b) =>
            b.delivery_initiated === 1 &&
            b.is_collected === 0 &&
            b.delivery_status === 3 &&
            b.delivery_payment_status === 1 &&
            b.order[0].status === "Goods arrived at Litudian - Ready to collect"
        );
        return (
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
            {data?.length > 0 ? (
              data.map((bid, i) => {
                return (
                  <BidItem
                    key={i}
                    bid={bid}
                    delivery_locations={delivery_locations}
                  />
                );
              })
            ) : (
              <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  No Orders
                </h1>
              </div>
            )}
          </div>
        );
      case 4:
        data = data.filter((b) => b.is_collected === 1);
        return (
          <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
            {data?.length > 0 ? (
              data.map((bid, i) => {
                return (
                  <BidItem
                    key={i}
                    bid={bid}
                    delivery_locations={delivery_locations}
                  />
                );
              })
            ) : (
              <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  No Orders
                </h1>
              </div>
            )}
          </div>
        );
      default:
        return (
          <div className="col-span-full flex flex-col justify-center items-center w-full py-10">
            <h1 className="text-[#A68F69] dark:text-tangerine">No Orders</h1>
          </div>
        );
    }
  };

  toggleShowDeliveryModal = (value) =>
    this.setState({ showDeliveryModal: value });

  handleCheckboxChange = (id) => {
    const { selectedBids } = this.state;
    if (selectedBids.includes(id)) {
      this.setState({
        selectedBids: selectedBids.filter((item) => item !== id),
      });
    } else {
      this.setState({ selectedBids: [...selectedBids, id] });
    }
  };

  clearSelectedBids = () => this.setState({ selectedBids: [] });

  render() {
    let {
      bids: { data },
    } = this.props;

    const {
      loading,
      section,
      deliverySection,
      showDeliveryModal,
      selectedBids,
    } = this.state;

    let bids = [],
      arrived = [];

    if (data?.length > 0) {
      bids = data.filter((b) => b?.active === 1);
    }

    if (bids?.length > 0) {
      arrived = bids.filter(
        (b) =>
          // b?.moq_fill_status === "full" &&
          b?.moq_order_status === "placed" &&
          b?.status === 1 &&
          (b?.is_collected === 1 || b?.is_collected === 0)
      );
    }

    return (
      <Layout>
        <Helmet prioritizeSeoTags>
          <meta charSet="utf-8" />

          <title>{`Litudian | My Orders`}</title>

          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <div className="grid grid-cols-12 mt-5 space-y-2 md:space-y-0">
          <div className="md:col-span-2 col-span-full">
            <h1 className="text-xl text-[#A68F69] dark:text-tangerine capitalize font-bold">
              My Orders
            </h1>
          </div>

          <div className="md:col-span-10 col-span-full flex flex-row justify-start items-center md:grid md:grid-cols-8 md:gap-y-2 md:gap-x-4 overflow-x-auto hide-scroll-bar space-x-1 pb-3 md:pb-0">
            <span
              className={`${
                section === 0 ? "bg-[#FFBF00]" : "bg-[#A68F69]"
              } text-white text-sm py-1 px-4 rounded capitalize shadow w-full cursor-pointer text-center whitespace-nowrap`}
              onClick={() => this.setState({ section: 0 })}
            >
              Pending MOQ
            </span>

            <span
              className={`${
                section === 1 ? "bg-[#FFBF00]" : "bg-[#A68F69]"
              } text-white text-sm py-1 px-4 rounded capitalize shadow w-full cursor-pointer text-center whitespace-nowrap`}
              onClick={() => this.setState({ section: 1 })}
            >
              Unpaid
            </span>

            <span
              className={`${
                section === 2 ? "bg-[#FFBF00]" : "bg-[#A68F69]"
              } text-white text-sm py-1 px-4 rounded capitalize shadow w-full cursor-pointer text-center whitespace-nowrap`}
              onClick={() => this.setState({ section: 2 })}
            >
              Paid
            </span>

            <span
              className={`${
                section === 3 ? "bg-[#FFBF00]" : "bg-[#A68F69]"
              } text-white text-sm py-1 px-4 rounded capitalize shadow w-full cursor-pointer text-center whitespace-nowrap`}
              onClick={() => this.setState({ section: 3 })}
            >
              On Transit
            </span>

            <span
              className={`${
                section === 4 ? "bg-[#FFBF00]" : "bg-[#A68F69]"
              } text-white text-sm py-1 px-4 rounded capitalize shadow w-full cursor-pointer text-center hidden whitespace-nowrap`}
              onClick={() => this.setState({ section: 4 })}
            >
              Received
            </span>
            <span
              className={`${
                section === 5 ? "bg-[#FFBF00]" : "bg-[#A68F69]"
              } text-white text-sm py-1 px-4 rounded capitalize shadow w-full cursor-pointer text-center whitespace-nowrap`}
              onClick={() => this.setState({ section: 5 })}
            >
              Arrived
            </span>
          </div>
        </div>

        {authenticated && (
          <div className="flex flex-col my-3">
            {section === 0 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {!loading &&
                  bids
                    .filter(
                      (bid) =>
                        bid?.moq_fill_status === "not full" &&
                        bid?.moq_order_status === "not placed"
                    )
                    .map((bid, i) => {
                      let bgColor =
                        this.getPercentage(bid) < 100 ? "#FFBF00" : "#8DC63F";

                      return (
                        <div
                          key={i}
                          className="grid grid-cols-7 h-fit max-h-fit border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B]"
                        >
                          <div className="col-span-3 rounded-l-[10px] h-full">
                            <a href={`/products/${bid?.moq_id}`}>
                              <img
                                className="h-[230px] object-cover w-full rounded-l-[10px]"
                                src={
                                  bid?.product[0]?.images
                                    ? bid?.product[0]?.images[0]
                                    : "https://via.placeholder.com/300"
                                }
                                alt={"product"}
                                onError={(e) => {
                                  e.target.src =
                                    "https://via.placeholder.com/300";
                                }}
                              />
                            </a>
                          </div>

                          <div className="col-span-4 h-full w-full bg-white dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-2">
                            <a href={`/products/${bid?.moq_id}`}>
                              <p className="text-[#5B5B5B] dark:text-white text-md w-full truncate break-words capitalize font-normal">
                                {bid?.product
                                  ? truncateText(
                                      toTitleCase(bid?.product[0]?.name),
                                      20
                                    )
                                  : "N/A"}
                              </p>
                            </a>

                            <div className="flex flex-row space-x-1">
                              <div className="flex flex-col flex-1">
                                <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                  Number of Items
                                </p>

                                <h1 className="text-[#5B5B5B] dark:text-white">
                                  {bid?.quantity}
                                </h1>
                              </div>
                            </div>

                            <div className="flex flex-col flex-1">
                              <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                Total Cost
                              </p>

                              <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                                KES {(bid?.amount).toLocaleString()}
                              </h1>

                              <div className="flex flex-row flex-1 w-full items-center relative justify-center">
                                <ProgressBar
                                  completed={this.getPercentage(bid)}
                                  className="w-full"
                                  bgColor={bgColor}
                                  height="16px"
                                  labelClassName={`text-white text-[12px] px-1 block min-w-full`}
                                  customLabel={""}
                                  labelAlignment="center"
                                />

                                <span className="text-white absolute left-0 text-[12px] px-1">{`${this.getPercentage(
                                  bid
                                )}% full`}</span>
                              </div>
                            </div>

                            <button
                              type="button"
                              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
                              onClick={() =>
                                (window.location.href = `/items/${bid?.id}`)
                              }
                            >
                              View Order
                            </button>
                          </div>
                        </div>
                      );
                    })}

                {!loading &&
                  bids.filter(
                    (bid) =>
                      bid?.moq_fill_status === "not full" &&
                      bid?.moq_order_status === "not placed"
                  ).length === 0 && (
                    <div className="col-span-full flex flex-col justify-center items-center w-full min-h-[400px]">
                      <h1 className="text-[#A68F69] dark:text-tangerine">
                        No Orders
                      </h1>
                    </div>
                  )}
              </div>
            )}

            {section === 1 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {!loading &&
                  bids
                    .filter(
                      (bid) =>
                        bid?.moq_fill_status === "full" &&
                        bid?.moq_order_status === "not placed" &&
                        bid?.status === 0
                    )
                    .map((bid, i) => {
                      let current_date = new Date(),
                        end_date = new Date(bid?.moq[0]?.end_date),
                        is_not_closed = end_date > current_date;
                      if (!is_not_closed) return;
                      return (
                        <div
                          key={i}
                          className="grid grid-cols-7 h-fit max-h-fit border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B]"
                        >
                          <div className="col-span-3 rounded-l-[10px] h-full">
                            <a href={`/products/${bid?.moq_id}`}>
                              <img
                                className="min-h-[230px] h-full object-cover w-full rounded-l-[10px]"
                                src={
                                  bid?.product[0]?.images
                                    ? bid?.product[0]?.images[0]
                                    : "https://via.placeholder.com/300"
                                }
                                alt={"product"}
                                onError={(e) => {
                                  e.target.src =
                                    "https://via.placeholder.com/300";
                                }}
                              />
                            </a>
                          </div>

                          <div className="col-span-4 h-full w-full bg-white dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-2">
                            <a href={`/products/${bid?.moq_id}`}>
                              <p className="text-[#5B5B5B] dark:text-white text-md w-full truncate break-words capitalize font-normal">
                                {bid?.product
                                  ? truncateText(
                                      toTitleCase(bid?.product[0]?.name),
                                      20
                                    )
                                  : "N/A"}
                              </p>
                            </a>

                            <div className="flex flex-row space-x-1">
                              <div className="flex flex-col flex-1">
                                <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                  Number of Items
                                </p>

                                <h1 className="text-[#5B5B5B] dark:text-white">
                                  {bid?.quantity}
                                </h1>
                              </div>
                            </div>

                            <div className="flex flex-col flex-1">
                              <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                Total Cost
                              </p>

                              <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                                KES {(bid?.amount).toLocaleString()}
                              </h1>
                            </div>

                            {bid && bid?.moq && bid?.moq[0]?.end_date && (
                              <div className="flex flex-col flex-1">
                                <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                  Time left until MOQ close
                                </p>

                                <Countdown
                                  endDate={`${bid?.moq[0]?.end_date}`}
                                />
                              </div>
                            )}

                            <button
                              type="button"
                              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
                              onClick={() =>
                                (window.location.href = `/items/${bid?.id}`)
                              }
                            >
                              View Order
                            </button>
                          </div>
                        </div>
                      );
                    })}

                {!loading &&
                  bids.filter(
                    (bid) =>
                      bid?.moq_fill_status === "full" &&
                      bid?.moq_order_status === "not placed" &&
                      bid?.status === 0
                  ).length === 0 && (
                    <div className="col-span-full flex flex-col justify-center items-center w-full min-h-[400px]">
                      <h1 className="text-[#A68F69] dark:text-tangerine">
                        No Orders
                      </h1>
                    </div>
                  )}
              </div>
            )}

            {section === 2 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {!loading &&
                  bids
                    .filter(
                      (bid) =>
                        bid?.moq_fill_status === "full" &&
                        bid?.moq_order_status === "not placed" &&
                        bid?.status === 1
                    )
                    .map((bid, i) => {
                      return (
                        <div
                          key={i}
                          className="grid grid-cols-7 h-fit max-h-fit border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B]"
                        >
                          <div className="col-span-3 rounded-l-[10px] h-full relative">
                            <a href={`/products/${bid?.moq_id}`}>
                              <img
                                className="h-[230px] object-cover w-full rounded-l-[10px]"
                                src={
                                  bid?.product[0]?.images
                                    ? bid?.product[0]?.images[0]
                                    : "https://via.placeholder.com/300"
                                }
                                alt={"product"}
                                onError={(e) => {
                                  e.target.src =
                                    "https://via.placeholder.com/300";
                                }}
                              />
                            </a>

                            {bid?.order &&
                              bid?.order?.length > 0 &&
                              bid?.order && (
                                <span className="absolute top-5 right-0 text-xs bg-black text-white px-4 py-1">
                                  {bid?.order[0]?.status}
                                </span>
                              )}
                          </div>

                          <div className="col-span-4 h-full w-full bg-white dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-2">
                            <a href={`/products/${bid?.moq_id}`}>
                              <p className="text-[#5B5B5B] dark:text-white text-md w-full truncate break-words capitalize font-normal">
                                {bid?.product
                                  ? truncateText(
                                      toTitleCase(bid?.product[0]?.name),
                                      20
                                    )
                                  : "N/A"}
                              </p>
                            </a>

                            <div className="flex flex-row space-x-1">
                              <div className="flex flex-col flex-1">
                                <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                  Number of Items
                                </p>

                                <h1 className="text-[#5B5B5B] dark:text-white">
                                  {bid?.quantity}
                                </h1>
                              </div>
                            </div>

                            <div className="flex flex-col flex-1">
                              <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                Total Cost
                              </p>

                              <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                                KES {(bid?.amount).toLocaleString()}
                              </h1>
                            </div>

                            <button
                              type="button"
                              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
                              onClick={() =>
                                (window.location.href = `/items/${bid?.id}`)
                              }
                            >
                              View Order
                            </button>
                          </div>
                        </div>
                      );
                    })}

                {!loading &&
                  bids?.filter(
                    (bid) =>
                      bid?.moq_fill_status === "full" &&
                      bid?.moq_order_status === "not placed" &&
                      bid?.status === 1
                  ).length === 0 && (
                    <div className="md:col-span-3 flex flex-col justify-center items-center w-full min-h-[400px]">
                      <h1 className="text-[#A68F69] dark:text-tangerine">
                        No Orders
                      </h1>
                    </div>
                  )}
              </div>
            )}

            {section === 3 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {!loading &&
                  bids
                    ?.filter(
                      (bid) =>
                        // bid?.moq_fill_status === "full" &&
                        bid?.moq_order_status === "placed" &&
                        bid?.status === 1 &&
                        bid?.is_collected === 0 &&
                        bid?.order[0].status !==
                          "Goods arrived at Litudian - Ready to collect"
                    )
                    .map((bid, i) => {
                      return (
                        <div
                          key={i}
                          className="grid grid-cols-7 h-fit max-h-fit border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B]"
                        >
                          <div className="col-span-3 rounded-l-[10px] h-full relative">
                            <a href={`/products/${bid?.moq_id}`}>
                              <img
                                className="h-[230px] object-cover w-full rounded-l-[10px]"
                                src={
                                  bid?.product[0]?.images
                                    ? bid?.product[0]?.images[0]
                                    : "https://via.placeholder.com/300"
                                }
                                alt={"product"}
                                onError={(e) => {
                                  e.target.src =
                                    "https://via.placeholder.com/300";
                                }}
                              />
                            </a>

                            {bid?.order &&
                              bid?.order?.length > 0 &&
                              bid?.order && (
                                <span className="absolute top-5 right-0 text-xs bg-black text-white px-4 py-1">
                                  {bid?.order[0]?.status}
                                </span>
                              )}
                          </div>

                          <div className="col-span-4 h-full w-full bg-white dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-2">
                            <a href={`/products/${bid?.moq_id}`}>
                              <p className="text-[#5B5B5B] dark:text-white text-md w-full truncate break-words capitalize font-normal">
                                {bid?.product
                                  ? truncateText(
                                      toTitleCase(bid?.product[0]?.name),
                                      20
                                    )
                                  : "N/A"}
                              </p>
                            </a>

                            <div className="flex flex-row space-x-1">
                              <div className="flex flex-col flex-1">
                                <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                  Number of Items
                                </p>

                                <h1 className="text-[#5B5B5B] dark:text-white">
                                  {bid?.quantity}
                                </h1>
                              </div>
                            </div>

                            <div className="flex flex-col flex-1">
                              <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                Total Cost
                              </p>

                              <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                                KES {(bid?.amount).toLocaleString()}
                              </h1>
                            </div>

                            <button
                              type="button"
                              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
                              onClick={() =>
                                (window.location.href = `/items/${bid?.id}`)
                              }
                            >
                              View Order
                            </button>
                          </div>
                        </div>
                      );
                    })}

                {!loading &&
                  bids?.filter(
                    (bid) =>
                      bid?.moq_fill_status === "full" &&
                      bid?.moq_order_status === "placed" &&
                      bid?.status === 1 &&
                      bid?.is_collected === 0
                  ).length === 0 && (
                    <div className="col-span-full flex flex-col justify-center items-center w-full min-h-[400px]">
                      <h1 className="text-[#A68F69] dark:text-tangerine">
                        No Orders
                      </h1>
                    </div>
                  )}
              </div>
            )}

            {section === 4 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 py-1">
                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {!loading &&
                  bids
                    .filter(
                      (bid) =>
                        bid?.moq_fill_status === "full" &&
                        bid?.moq_order_status === "placed" &&
                        bid?.status === 1 &&
                        bid?.is_collected === 1
                    )
                    .map((bid, i) => {
                      return (
                        <div
                          key={i}
                          className="grid grid-cols-7 h-fit max-h-fit border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B]"
                        >
                          <div className="col-span-3 rounded-l-[10px] h-full relative">
                            <a href={`/products/${bid?.moq_id}`}>
                              <img
                                className="h-[230px] object-cover w-full rounded-l-[10px]"
                                src={
                                  bid?.product[0]?.images
                                    ? bid?.product[0]?.images[0]
                                    : "https://via.placeholder.com/300"
                                }
                                alt={"product"}
                                onError={(e) => {
                                  e.target.src =
                                    "https://via.placeholder.com/300";
                                }}
                              />
                            </a>

                            {bid?.order &&
                              bid?.order?.length > 0 &&
                              bid?.order && (
                                <span className="absolute top-5 right-0 text-xs bg-black text-white px-4 py-1">
                                  {bid?.order[0]?.status}
                                </span>
                              )}
                          </div>

                          <div className="col-span-4 h-full w-full bg-white dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-2">
                            <a href={`/products/${bid?.moq_id}`}>
                              <p className="text-[#5B5B5B] dark:text-white text-md w-full truncate break-words capitalize font-normal">
                                {bid?.product
                                  ? truncateText(
                                      toTitleCase(bid?.product[0]?.name),
                                      20
                                    )
                                  : "N/A"}
                              </p>
                            </a>

                            <div className="flex flex-row space-x-1">
                              <div className="flex flex-col flex-1">
                                <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                  Number of Items
                                </p>

                                <h1 className="text-[#5B5B5B] dark:text-white">
                                  {bid?.quantity}
                                </h1>
                              </div>
                            </div>

                            <div className="flex flex-col flex-1">
                              <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                                Total Cost
                              </p>

                              <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                                KES {(bid?.amount).toLocaleString()}
                              </h1>
                            </div>

                            <button
                              type="button"
                              className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
                              onClick={() =>
                                (window.location.href = `/items/${bid?.id}`)
                              }
                            >
                              View Order
                            </button>
                          </div>
                        </div>
                      );
                    })}

                {!loading &&
                  bids?.filter(
                    (bid) =>
                      bid?.moq_fill_status === "full" &&
                      bid?.moq_order_status === "placed" &&
                      bid?.status === 1 &&
                      bid?.is_collected === 1
                  ).length === 0 && (
                    <div className="md:col-span-3 flex flex-col justify-center items-center w-full min-h-[400px]">
                      <h1 className="text-[#A68F69] dark:text-tangerine">
                        No Orders
                      </h1>
                    </div>
                  )}
              </div>
            )}

            {section === 5 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {loading && (
                  <BlockLoader uniqueKey="key1" height={250} radius={6} />
                )}

                {!loading && (
                  <div className="grid grid-cols-12 md:mt-2 space-y-2 md:space-y-0 col-span-full">
                    <div className="md:col-span-2 col-span-full"></div>
                    <div className="md:col-span-8 col-span-full md:grid md:grid-cols-12 md:gap-y-2 md:gap-x-4 flex flex-row items-center justify-start space-x-1 md:space-x-0 overflow-x-auto hide-scroll-bar pb-4 md:pb-0">
                      <div
                        className={`${
                          deliverySection === 0
                            ? "bg-[#FFBF00]"
                            : "bg-green-500"
                        } text-white flex flex-col justify-center items-center text-xs py-[3px] px-2 md:px-0 rounded capitalize shadow w-full cursor-pointer text-center md:col-span-2`}
                        onClick={() => this.setState({ deliverySection: 0 })}
                      >
                        <span className="whitespace-nowrap">Warehouse</span>
                      </div>

                      <div
                        className={`${
                          deliverySection === 5
                            ? "bg-[#FFBF00]"
                            : "bg-green-500"
                        } text-white flex flex-col justify-center items-center text-xs py-[3px] px-2 md:px-0 rounded capitalize shadow w-full cursor-pointer text-center md:col-span-2`}
                        onClick={() => this.setState({ deliverySection: 5 })}
                      >
                        <span className="whitespace-nowrap">
                          Ready for Delivery
                        </span>
                      </div>

                      <div
                        className={`${
                          deliverySection === 1
                            ? "bg-[#FFBF00]"
                            : "bg-green-500"
                        } text-white flex flex-col justify-center items-center text-xs py-[3px] px-2 md:px-0 rounded capitalize shadow w-full cursor-pointer text-center md:col-span-2`}
                        onClick={() => this.setState({ deliverySection: 1 })}
                      >
                        <span className="whitespace-nowrap">Scheduled</span>
                      </div>

                      <div
                        className={`${
                          deliverySection === 2
                            ? "bg-[#FFBF00]"
                            : "bg-green-500"
                        } text-white flex flex-col justify-center items-center text-xs py-[3px] px-2 md:px-0 rounded capitalize shadow w-full cursor-pointer text-center md:col-span-2`}
                        onClick={() => this.setState({ deliverySection: 2 })}
                      >
                        <span className="whitespace-nowrap">Transit</span>
                      </div>

                      <div
                        className={`${
                          deliverySection === 3
                            ? "bg-[#FFBF00]"
                            : "bg-green-500"
                        } text-white flex flex-col justify-center items-center text-xs py-[3px] px-2 md:px-0 rounded capitalize shadow w-full cursor-pointer text-center md:col-span-2`}
                        onClick={() => this.setState({ deliverySection: 3 })}
                      >
                        <span className="whitespace-nowrap">Delivered</span>
                      </div>

                      <div
                        className={`${
                          deliverySection === 4
                            ? "bg-[#FFBF00]"
                            : "bg-green-500"
                        } text-white flex flex-col justify-center items-center text-xs py-[3px] px-2 md:px-0 rounded capitalize shadow w-full cursor-pointer text-center md:col-span-2`}
                        onClick={() => this.setState({ deliverySection: 4 })}
                      >
                        <span className="whitespace-nowrap">Collected</span>
                      </div>
                    </div>
                  </div>
                )}

                {!loading && deliverySection === 5 && arrived.length > 0 && (
                  <div className="col-span-full">
                    <button
                      className="bg-tangerine text-white capitalize px-4 py-2 rounded text-base disabled:bg-tangerine/20 disabled:dark:text-gray-400 flex flex-row justify-center items-center space-x-2 fixed bottom-10 right-10 z-50"
                      disabled={selectedBids.length === 0}
                      onClick={() => {
                        if (selectedBids?.length > 0) {
                          this.toggleShowDeliveryModal(true);
                        }
                      }}
                    >
                      <div className="flex flex-row justify-center items-center space-x-2 pt-1">
                        <img
                          className="h-8 object-contain"
                          src="/img/fast-delivery.png"
                          alt="fast-delivery.png"
                        />
                        <span>Request Delivery</span>
                      </div>
                    </button>
                  </div>
                )}

                {!loading && deliverySection === 5 && (
                  <div className="flex flex-col col-span-full justify-center">
                    <p className="dark:text-white md:text-left text-center text-lg">
                      Please select all the items you would like to request
                      delivery for.
                    </p>
                    <p className="font-extrabold italic dark:text-white md:text-left text-center">
                      Note: selecting multiple items for delivery results to
                      cheaper delivery rates
                    </p>
                  </div>
                )}

                {!loading && this.renderSwitch(deliverySection, arrived)}

                {!loading && arrived.length === 0 && (
                  <div className="md:col-span-3 flex flex-col justify-center items-center w-full min-h-[400px]">
                    <h1 className="text-[#A68F69] dark:text-tangerine">
                      No Orders
                    </h1>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <DeliveryModal
          isOpen={showDeliveryModal}
          setIsOpen={this.toggleShowDeliveryModal}
          selectedBids={selectedBids}
          selectItem={this.handleCheckboxChange}
          clearSelectedBids={this.clearSelectedBids}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    moqs: state.moqs,
    bids: state.bids,
    delivery_locations: state.delivery_locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCategories, getMoqs, getUserBids, getDeliveryLocations },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
