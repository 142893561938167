import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import instance from '../utils/instance'

export default function ChangePassword(props) {
    const { open, toggle } = props;
    let [password, setPassword] = useState('')
    let [confirmPassword, setConfirmPassword] = useState('')

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={toggle}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/90" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <form onSubmit={async (e) => {
                                        try {
                                            e.preventDefault();
                                            let uid = localStorage.getItem('ltdn_uid');
                                            if (password === '' || confirmPassword === '') {
                                                return toast.error('Please fill all fields !', { autoClose: 10000 })
                                            }
                                            if (password !== confirmPassword ) {
                                                return toast.error('Password must match confirm password !', { autoClose: 10000 })
                                            }
                                            let response = instance.patch(`users/${uid}`, { password });
                                            if (response) {
                                                setPassword('')
                                                setConfirmPassword('')
                                                toast.success('Password updated successfully !', { autoClose: 10000 })
                                                return toggle()
                                            }
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }}>
                                        <div className="mt-2">
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>New Password</h2>
                                            <input className='py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0] w-full mb-3' type={'password'} name='password' value={password} onChange={(e) => setPassword(e.target.value)}  />
                                            <h2 className='text-md text-gray-600 font-bold mb-2'>Confirm Password</h2>
                                            <input className='py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none bg-[#f0f0f0] w-full' type={'password'}  name='confirmPassword' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}   />
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="bg-tangerine text-white w-full py-2 my-5 font-bold rounded-md"
                                            >
                                                Update Password
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
