import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default function FAQ_2() {
    return (
        <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Where is the location of the delivery company (Boxleo) for pick up?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        The BoxLeo warehouse is located along Mombasa Road, Akshrab Godowns, Gate A-2, JKIA Junction, Kenya. They are open 
                        every Monday-Saturday, 8AM to 6PM.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        I would like to use my own riders to collect my arrived goods. Is this possible?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes. If you would like to use your own riders, you can have them collect the items from the BoxLeo warehouse or arrange for BoxLeo and their rider to meet in the CBD, where delivery is also free.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How much is delivery to CBD? Is collection from the warehouse also charged?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We do not charge deliveries for orders within the Nairobi CBD. 
                        Self pick-ups from the Boxleo warehouse is also free of charge.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What happens if the items that is delivered is not what I ordered?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Once you receive deliveries, as always ensure it is what you ordered and 
                        is in perfect working condition according to the description given. 
                        If there is an issue while you are receiving items, you have the right to reject it 
                        and have it returned.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How soon will I receive my package after requesting for delivery?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        For deliveries within Nairobi, all requests made before 11 am will be handled the same day. 
                        Any delivery requests after will get to you by midday the next day. 
                        For outbound (deliveries outside Nairobi), you will receive your packages the next morning.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How long should I wait before requesting for a delivery?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        You can and should request for delivery as soon as you are notified that your goods have arrived. 
                        However, goods stored for more than 14 days will attract storage fees depending on size and weight.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    );
}
