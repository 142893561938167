import React, { useState } from "react";
import Modal from "../components/Modal";
import { Dialog } from "@headlessui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import Label from "../components/Label";
import Input from "../components/Input";
import InputError from "../components/InputError";
import Button from "../components/Button";
import { toast } from "react-toastify";
import instance from "../utils/instance";

const schema = Yup.object().shape({
  product_name: Yup.string()
    .min(3)
    .required("Product Name is a required field"),
  product_link: Yup.string()
    .url("Product link must be a valid URL")
    .required("Product Link is a required field"),
  quantity: Yup.number().required(),
  description: Yup.string(),
});

function Index(props) {
  // Props
  const { isOpen, setIsOpen, product_link = "", product_name = "" } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Functions
  const requestMoq = async (values) => {
    setLoading(true);
    let response = instance.post("recommendations", values);
    if (response) {
      setLoading(false);
      toast.success("Request Submitted Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsOpen(false);
    }
    try {
    } catch (error) {
      setLoading(false);
      toast.error(
        "Could not complete your request at this time. Please try again later !",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="space-y-8">
      <div className="flex flex-row items-center justify-between w-full">
        <Dialog.Title
          as="h3"
          className="text-lg font-bold leading-6 text-gray-900"
        >
          Request to re-run MOQ
        </Dialog.Title>
        <button onClick={() => setIsOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          product_name: product_name,
          product_link: product_link,
          quantity: "",
          description: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          requestMoq(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              <Label htmlFor="product_name">Product Name</Label>

              <Input
                id="product_name"
                type="text"
                value={values.product_name}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                // required
                disabled
                autoFocus
              />

              <InputError messages={[errors.product_name]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="product_link">Product Link</Label>

              <Input
                id="product_link"
                type="text"
                value={values.product_link}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                // required
                disabled
                autoFocus
              />

              <InputError messages={[errors.product_link]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="quantity">Quantity</Label>

              <Input
                id="quantity"
                type="number"
                value={values.quantity}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                // required
                autoFocus
                min={0}
              />

              <InputError messages={[errors.quantity]} className="mt-2" />
            </div>
            <div className="mt-4">
              <Label htmlFor="quantity">Description</Label>

              <textarea
                id="description"
                value={values.description}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3 py-3"
                onChange={handleChange}
                placeholder="(Optional) Describe the product weight, size, colors e.t.c"
              ></textarea>

              <InputError messages={[errors.description]} className="mt-2" />
            </div>
            <div className="flex flex-col items-center justify-center mt-8 space-y-2">
              <Button
                className="w-full flex flex-col items-center justify-center py-3 bg-tangerine"
                type="submit"
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default Index;
