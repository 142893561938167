import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class SliderComponent extends Component {

    constructor(props) {

        super(props);
    
        this.state = {


             
        }

    }
    
    render() {

        const settings = {

            dots: true,

            autoplay: true,

            infinite: true,

            speed: 500,

            slidesToShow: 1,

            slidesToScroll: 1,

            arrows: false,

            appendDots: dots => (

                <div

                    style={{

                        position: "absolute",

                        bottom: '10px',

                        borderRadius: "10px",

                        padding: "10px"

                    }}

                >

                  <ul style={{ margin: "0px" }}> {dots} </ul>

                </div>

            )

        };

        const { promotions } = this.props;

        return (

            <div>

                <Slider {...settings}>
                    
                    {promotions && promotions.length > 0 && promotions.map((promo, i) => <div key={i} className="h-72">

                        <div className='flex flex-col relative h-full'>

                            <img className='w-full object-cover h-full' src={promo.image_url} alt={promo.title} />

                            <div className="absolute top-0 left-0 bg-black/20 rounded-md flex flex-1 flex-col justify-end items-center w-full p-10 h-full space-y-4">

                                <a href={`${promo?.item_url}`}><h3 className="text-white text-4xl font-bold">{promo.title}</h3></a>

                                <a href={`${promo?.item_url}`}><p className="text-white text-lg font-bold">{promo.description}</p></a>
                                

                            </div>

                            {/* {promo?.item_url && promo?.item_url !== '' && <div onClick={() => window.location.href === `${promo?.item_url}`} className="absolute top-0 left-0 bg-transparent z-10 rounded-md flex flex-1 flex-col justify-end items-center w-full p-10 h-full space-y-4 cursor-pointer" ></div>} */}

                        </div>

                    </div>)}

                    {!promotions && <div className="h-72">

                        <div className="bg-litudian-orange rounded-md flex flex-1 flex-row justify-center items-center w-full p-10 h-full">

                            <h3 className="text-black text-4xl font-bold">Promo !</h3>

                        </div>
                        
                    </div>}
                    
                </Slider>
                
            </div>

        )

    }

};

export default SliderComponent;
