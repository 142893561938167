import React, { useState, useEffect } from 'react';

export default function Countdown({ endDate }) {
    // console.log(new Date(endDate))
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  let { days, hours, minutes, seconds } = timeLeft;
  if (days === undefined) {
    days = 0
  }
  if (hours === undefined) {
    hours = 0
  }
  if (minutes === undefined) {
    minutes = 0
  }
  if (seconds === undefined) {
    seconds = 0
  }

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    return <h1 className='text-[#5B5B5B] dark:text-white text-xl'>Closed</h1>
  }

  if (days > 1) {
    return <h1 className='text-[#5B5B5B] dark:text-white text-xl'>{days} days left</h1>
  }

  return (
    <h1 className='text-[#5B5B5B] dark:text-white text-xl'>
      {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} left
    </h1>
  );
}

function calculateTimeLeft(endDate) {
  const difference = +new Date(endDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)) || '00',
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24) || '00',
      minutes: Math.floor((difference / 1000 / 60) % 60) || '00',
      seconds: Math.floor((difference / 1000) % 60) || '00',
    };
  }

  return timeLeft;
}