import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthModalContext } from "./../contexts/auth";
import NotAuthenticated from "../pages/NotAuthenticated";

const ProtectedRoute = () => {
  const expiry = localStorage.getItem("ltdn_exp");
  const currentTime = Date.now() / 1000;
  const authenticated = expiry > currentTime;

  // contexts
  const { toggleModal } = useContext(AuthModalContext);

  //Effects
  useEffect(() => {
    if (!authenticated) {
      toggleModal();
    }
  }, []);

  return authenticated ? <Outlet /> : <NotAuthenticated />;
};

export default ProtectedRoute;
