export const CATEGORIES = 'CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const MOQS = 'MOQS';
export const PRODUCTS = 'PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT'
export const ALL_MOQS = 'ALL_MOQS';
export const SEARCH_MOQS = 'SEARCH_MOQS';
export const FETCH_MORE_MOQS = 'FETCH_MORE_MOQS';
export const GET_MOQ = 'GET_MOQ';
export const CURRENT_USER = 'CURRENT_USER';
export const REVIEWS = 'REVIEWS';
export const BIDS = 'BIDS';
export const SINGLE_BID = 'SINGLE_BID';
export const DELIVERY_LOCATIONS = 'DELIVERY_LOCATIONS';
export const RECENT_SEARCHES = 'RECENT_SEARCHES'
export const RECENT_MOQS = 'RECENT_MOQS'