import { BIDS, SINGLE_BID } from './../types/Index';

const initialState = {
    data: [],
    bid: null
};

export default function BidReducer(state = initialState, action) {

    switch(action.type) {

        case BIDS: 

            return {

                ...state,

                data: action.payload,

            };

        case SINGLE_BID: 

            return {

                ...state,

                bid: action.payload

            };

        default:

            return state;

    }

};