import { BIDS, SINGLE_BID } from './../types/Index';
import instance from './../../utils/instance';

const expiry = localStorage.getItem('ltdn_exp');
const currentTime = Date.now() / 1000;
const authenticated = expiry > currentTime;

export const getUserBids = () => async dispatch => {

    try {

        let uid = localStorage.getItem('ltdn_uid');

        const response = await instance.get(`/users/${uid}`);

        if (response?.data && response?.data?.bids) {

            dispatch({ type: BIDS, payload: response?.data?.bids });

        }

        return response.data;
        
    } catch (error) {

        throw error.response.data;
        
    }

};

export const createBid = (data) => async dispatch => {

    try {

        const response = await instance.post('admin_bid', data);

        return response?.data;
        
    } catch (error) {

        throw (error);
        
    }

};

export const updateBid = (id, data) => async dispatch => {

    try {

        const response = await instance.patch(`admin_bid/${id}`, data);

        return response?.data;
        
    } catch (error) {

        throw (error);
        
    }

};

export const showBid = (id) => async dispatch => {

    try {

        const response = authenticated ? await instance.get(`admin_bid/${id}`) : await instance.get(`public_bid/${id}`);

        if (response?.data && response?.data.length > 0) {

            dispatch({ type: SINGLE_BID, payload: response?.data[0] });

        }

        return response?.data;
        
    } catch (error) {

        throw (error);
        
    }

};

export const cancelBid = (id) => async dispatch => {

    try {

        const response = await instance.patch(`admin_bid/${id}`, { active: 0 });

        return response?.data;
        
    } catch (error) {

        throw (error);
        
    }

};
