import React, { Component } from 'react';
import Layout from './../layouts/Main';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Index extends Component {

    constructor(props) {

        super(props);
    
        this.state = {


             
        }

    }

    componentDidMount = async () => {



    }
    
    render() {

        return (

            <Layout>

                <div className="flex flex-col justify-center items-center w-full h-[500px] space-y-6 py-5 dark:text-white">

                    <h1 className='uppercase text-6xl font-bold text-tangerine'>404 ERROR</h1>

                    <p className='text-2xl'>Sorry, page not found</p>

                </div>

            </Layout>

        )

    }

};

const mapStateToProps = (state) => {

    return {

        

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({  }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
