import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "./../../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showBid,
  cancelBid,
  updateBid,
} from "./../../store/actions/BidActions";
import { getProductById } from "./../../store/actions/ProductActions";
import { getMoqById } from "./../../store/actions/MoqActions";
import { toast } from "react-toastify";

function Edit(props) {
  const {
    bids: { bid },
    products: { product },
    moqs: { moq },
  } = props;

  const [img, setImg] = useState(product !== null ? product?.images[0] : "");

  const [loading, setLoading] = useState(false);

  const [quantity, setQuantity] = useState(0);

  const [shipping, setShipping] = useState("AIR");

  const [bidAttributes, setBidAttributes] = useState([
    { size_id: 0, quantity: 0, color_id: 0 },
  ]);

  const params = useParams();

  // Functions
  const handleAddClick = () => {
    setBidAttributes([
      ...bidAttributes,
      { size_id: 0, quantity: 0, color_id: 0 },
    ]);
  };

  const handleRemoveClick = (index) => {
    let list = [...bidAttributes];

    list.splice(index, 1);

    setBidAttributes(list);
  };

  const handleInputChange = (e, index, color_id = false) => {
    const { name, value } = e.target;

    let attributes = [...bidAttributes];

    if (color_id === true) {
      attributes[index]["color_id"] = parseInt(value);
    } else {
      attributes[index][name] = parseInt(value);
    }

    if (
      product?.readable_sizes.length === 1 &&
      (product?.readable_sizes[0]?.label === "None" ||
        product?.readable_sizes[0]?.label === "none")
    ) {
      attributes[index]["size_id"] = product?.readable_sizes[0]?.id;
    }

    setBidAttributes(attributes);
  };

  useEffect(() => {
    async function fetchBid(id) {
      let response = await props.showBid(id);

      // console.log(response)

      if (response && response?.length > 0 && response[0]?.product[0]?.id) {
        await props.getProductById(response[0]?.product[0]?.id);
      }
    }

    fetchBid(params?.id);
  }, []);

  useEffect(() => {
    if (product !== null && product?.images[0]) {
      setImg(product?.images[0]);
    }
  }, [product]);

  useEffect(() => {
    if (bid && bid?.bid_attributes && bid?.bid_attributes?.length > 0) {
      bid?.bid_attributes.forEach((attr) => {
        delete attr?.sizes;

        delete attr?.colors;
      });

      setBidAttributes(bid?.bid_attributes);
    }

    if (bid) {
      setQuantity(bid?.quantity);

      setShipping(bid?.shipping);
    }

    if (bid?.moq && bid?.moq?.length > 0) {
      async function fetchMOQ(id) {
        try {
          await props.getMoqById(id);
        } catch (error) {
          console.log(error);
        }
      }
      fetchMOQ(bid?.moq[0]?.id);
    }
  }, [bid]);

  const updateBid = async () => {
    try {
      let data = [],
        qty = 0,
        values = {};

      values.quantity = quantity;

      if (moq?.below_moq_price && moq?.below_moq_price === 0  && (quantity === 0 || quantity < bid?.moq[0]?.min_quantity)) {
        return toast.warning(
          `Please provided the required quantity which must be ${bid?.moq[0]?.min_quantity} or above`,
          {
            position: toast.POSITION.TOP_RIGHT,

            autoClose: 10000,
          }
        );
      }

      for (let i = 0; i < bidAttributes.length; i++) {
        if (bidAttributes[i].size_id === 0) {
          return toast.warning(
            "Please select appropriate size in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (bidAttributes[i].color_id === 0) {
          return toast.warning(
            "Please select appropriate color in order attributes!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }

        if (bidAttributes[i].quantity === 0) {
          return toast.warning(
            "Quantity on order attributes should be more than zero!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );
        }
      }

      if (
        bidAttributes.length > 0 &&
        bidAttributes[0].size_id !== 0 &&
        bidAttributes[0].quantity !== 0 &&
        bidAttributes[0].color_id !== 0
      ) {
        data = bidAttributes;

        bidAttributes.forEach((attribute) => {
          qty = qty + attribute.quantity;
        });

        if (parseInt(values.quantity) !== qty) {
          toast.warning(
            "Sum of quantity on order attrbutes must be same as MOQ quantity provided!",
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 10000,
            }
          );

          return null;
        }
      }

      values.bid_attributes = data;

      values.shipping = shipping;

      setLoading(true);

      const result = await props.updateBid(bid?.id, values);

      if (result) {
        toast.success("Order Updated Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setBidAttributes([{ size_id: 0, quantity: 0, color_id: 0 }]);

        setQuantity(0);

        setLoading(false);

        setTimeout(() => {
          return (window.location.href = `/items/${bid?.id}`);
        }, 3000);
      }
    } catch (error) {
      setLoading(false);

      if (
        error &&
        error?.response?.data &&
        error?.response?.data !== undefined &&
        error?.response?.data?.message !== undefined
      ) {
        return toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      toast.error("Please try again later !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function findBelowMOQPriceMinMax(arr) {
    let min = 0;

    let max = 0;

    if (arr === undefined) {
      return { min, max };
    } else {
      min = arr && arr?.length > 0 ? arr[0].below_moq_price || 0 : 0;

      max = arr && arr?.length > 0 ? arr[0].below_moq_price || 0 : 0;
    }

    if (arr && arr?.length > 0) {
      for (let i = 1, len = arr.length; i < len; i++) {
        let v = arr[i].below_moq_price || 0;
        min = v < min ? v : min;
        max = v > max ? v : max;
      }
    }

    return [min, max];
  }

  function getBelowMOQPrice(value) {
    let varying_sizes = value?.product[0]?.varying_sizes || 0;
    if (varying_sizes === 1) {
      let data = findBelowMOQPriceMinMax(value?.moq_attributes);
      if (data?.length === 2 && data[0] === data[1]) {
        return data[0]?.toLocaleString();
      } else {
        return `${data[0]?.toLocaleString()} - ${data[1]?.toLocaleString()}`;
      }
    } else {
      return value?.below_moq_price?.toLocaleString() || 0;
    }
  }

  let below_moq_price = 0;

  if (moq) {
    below_moq_price = getBelowMOQPrice(moq);
  }

  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5">
        <div className="md:col-span-12"></div>

        <div className="md:col-span-4">
          <div className="h-full w-full flex flex-col">
            <div className="w-full">
              <img
                className="h-100 object-cover w-full"
                src={img || "https://via.placeholder.com/300"}
                alt={"product"}
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/300";
                }}
              />
            </div>

            {product !== null &&
              product.images !== undefined &&
              product.images.length > 1 && (
                <div className="grid grid-cols-4 gap-2 mt-3">
                  {product.images.map((image, i) => (
                    <div
                      key={i}
                      className="bg-white w-full rounded-md shadow-sm cursor-pointer"
                      onClick={() =>
                        setImg(image || "https://via.placeholder.com/300")
                      }
                    >
                      <img
                        className="h-full object-contain w-full"
                        src={image || "https://via.placeholder.com/300"}
                        alt={"product"}
                        onError={(e) => {
                          e.target.src = "https://via.placeholder.com/300";
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>

        <div className="md:col-span-8 bg-[#f9f9f9] rounded px-4 md:px-8 py-4 md:py-8">
          <form
            className="flex flex-col space-y-3"
            onSubmit={(e) => {
              e.preventDefault();

              updateBid();
            }}
          >
            <h2 className="text-[#A68F69] font-bold text-lg">Total Quantity</h2>

            <input
              className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full"
              type={"number"}
              min={below_moq_price > 0 || below_moq_price.length > 0 ? 1 : bid?.moq[0]?.min_quantity}
              name="quantity"
              onChange={(e) => setQuantity(e.target.value)}
              value={quantity}
            />

            <h2 className="text-[#A68F69] font-bold text-lg">
              Order Attributes
            </h2>

            {bidAttributes.map((x, i) => (
              <div
                key={i}
                className="w-full flex flex-col md:flex-row md:justify-between md:items-center mb-5 space-y-3 md:space-y-0"
              >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-y-0 md:gap-x-3">
                  <div
                    className={`${
                      product?.readable_sizes &&
                      product?.readable_sizes.length === 1 &&
                      (product?.readable_sizes[0]?.label === "None" ||
                        product?.readable_sizes[0]?.label === "none")
                        ? "hidden"
                        : "block"
                    } space-y-2 w-full`}
                  >
                    <h4 className="text-gray-600 font-semibold">
                      Size/Pattern
                    </h4>

                    <div className="">
                      <select
                        name="size_id"
                        value={x.size_id}
                        className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full"
                        onChange={(e) => handleInputChange(e, i)}
                      >
                        <option value={""}>Select Size/Pattern</option>

                        {product?.readable_sizes &&
                          product?.readable_sizes.length > 0 &&
                          product?.readable_sizes.map((size, i) => (
                            <option
                              key={i}
                              value={size.id}
                              selected={
                                (size?.label === "None" ||
                                  size?.label === "none") &&
                                product?.readable_sizes.length === 1
                              }
                            >
                              {size?.label}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="md:space-y-2 flex-1 min-w-[200px]">
                    <h4 className="text-gray-600 font-semibold">Color</h4>

                    <div className="flex flex-row items-center space-x-1 w-full flex-1">
                      <select
                        name="color_id"
                        value={x.color_id}
                        className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full"
                        onChange={(e) => {
                          handleInputChange(e, i);
                        }}
                      >
                        <option value={""}>Select Color</option>

                        {product?.readable_colors &&
                          product?.readable_colors.length > 0 &&
                          product?.readable_colors.map((color, i) => (
                            <option key={i} value={color.id}>
                              {color?.label}
                            </option>
                          ))}
                      </select>

                      <div
                        className="h-[50px] w-[70px] rounded shadow-sm border border-[#A68F69]"
                        style={{
                          backgroundColor: `${
                            product?.readable_colors &&
                            product?.readable_colors.find(
                              (color) => color?.id === parseInt(x.color_id)
                            )?.value
                          }`,
                        }}
                      ></div>
                    </div>

                    {/* <div className='grid grid-cols-1 md:grid-cols-3 gap-1'>

                                            {product?.readable_colors && product?.readable_colors.length > 0 && product?.readable_colors.map((color, z) => <div key={z} className='flex flex-row items-center justify-start space-x-2'>
                                                
                                                <input type="radio" name={`color_id-${i}`} value={color.id} onChange={e => handleInputChange(e, i, true)} />

                                                <div className='flex flex-row items-center justify-start space-x-2  border border-[#A68F69] rounded'>

                                                    <div className='h-5 w-5 rounded shadow-sm' style={{ backgroundColor: `${color?.value}` }}></div>

                                                </div>
                                                
                                            </div>)}
                                            
                                        </div> */}
                  </div>

                  <div className="md:space-y-2">
                    <h4 className="text-gray-600 font-semibold">Quantity</h4>

                    <input
                      className="focus:outline-none border border-[#A68F69] box-border px-2 py-3 text-base font-bold rounded focus:ring-2 ring-[#A68F69] w-full"
                      type={"number"}
                      min={0}
                      name="quantity"
                      value={x.quantity}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-between items-center md:items-end space-x-1 px-1 md:px-0 md:space-x-2 h-full">
                  {bidAttributes.length - 1 === i && (
                    <button
                      className="bg-tangerine text-white p-2 rounded shadow-sm text-sm"
                      onClick={() => handleAddClick()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </button>
                  )}

                  {bidAttributes.length !== 1 && (
                    <button
                      className="bg-tangerine text-white p-2 rounded shadow-sm text-sm"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}

            <h1 className="text-[#A68F69] font-bold text-lg">
              Shipping Method
            </h1>

            <div className="gap-y-3  md:gap-y-0 md:gap-x-3 my-4 grid grid-cols-1 md:grid-cols-2 mb-4">
              <div
                className="flex flex-row relative cursor-pointer bg-white rounded-xl"
                onClick={() => setShipping("SEA")}
              >
                <div className="px-4 bg-green-500 text-white flex flex-col justify-center items-center rounded-l-xl">
                  <img className="m-0 p-0" src="/img/ship.png" alt="ship" />
                </div>

                <div className="flex flex-col flex-1 px-2 py-3 border-[#A68F69] border-y-2 border-r-2 rounded-r-xl">
                  <h4 className="text-[#5B5B5B] text-sm mb-0 font-bold">Sea</h4>

                  <p className="text-xs text-[#5B5B5B]">{"-"}</p>
                </div>

                {shipping === "SEA" && (
                  <div className="absolute -top-2 -right-2 bg-[#A68F69] rounded-full text-white p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )}
              </div>

              <div
                className="flex flex-row relative cursor-pointer bg-white rounded-xl"
                onClick={() => setShipping("AIR")}
              >
                <div className="px-4 bg-blue-500 text-white flex flex-col justify-center items-center rounded-l-xl">
                  <img className="m-0 p-0" src="/img/plane.png" alt="plane" />
                </div>

                <div className="flex flex-col flex-1 px-2 py-3 border-[#A68F69] border-y-2 border-r-2 rounded-r-xl">
                  <h4 className="text-[#5B5B5B] text-sm mb-0 font-bold">Air</h4>

                  <p className="text-xs text-[#5B5B5B]">{"-"}</p>
                </div>

                {shipping === "AIR" && (
                  <div className="absolute -top-2 -right-2 bg-[#A68F69] rounded-full text-white p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-end items-center md:py-10 space-y-3 md:space-y-0">
              <button
                className="w-full md:w-fit px-20 rounded-full bg-tangerine text-white py-2 font-bold"
                type="submit"
              >
                {loading ? "Wait..." : "Edit Order"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories,

    moqs: state.moqs,

    bids: state.bids,

    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showBid, getProductById, cancelBid, updateBid, getMoqById },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
