import React, { Component } from 'react';
import Layout from './../layouts/Main';

export default class Account extends Component {

    constructor(props) {

        super(props);
    
        this.state = {


             
        }

    }
    
    render() {

        return (

            <Layout>

                <div className="grid grid-cols-3 gap-4 py-5">

                    
                    
                </div>
                
            </Layout>

        )

    }

};
