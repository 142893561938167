import React, { Component } from 'react';
import Layout from './../layouts/Main';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Index extends Component {

    constructor(props) {

        super(props);
    
        this.state = {


             
        }

    }

    componentDidMount = async () => {



    }
    
    render() {

        return (

            <Layout>

                <div className="flex flex-col space-y-6 py-5 dark:text-white">

                    <h1 className='uppercase text-2xl font-bold text-tangerine'>COOKIE POLICY</h1>

                    <p>This Cookie Policy explains how Litudian Enterprises ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our websites at http://www.litudian.com ("Websites"). It explains what these technologies are, why we use them, and your rights to control our use of them.</p>

                    <p>Sometimes we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>What are cookies?</h1>

                    <p>Cookies are small data files placed on your computer or mobile device when you visit a website. Website owners widely use cookies to make their websites work or work more efficiently, as well as to provide reporting information.</p>

                    <p>Cookies set by the website owner (in this case, Litudian Enterprises) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer when it visits the website in question and when it visits certain other websites.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>Why do we use cookies?</h1>

                    <p>We use first and third-party cookies for several reasons. Some cookies are required for technical reasons for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. </p>

                    <p>The specific types of first and third-party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit:</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>How can I control cookies?</h1>

                    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide services.</p>

                    <p>If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also modify your web browser controls to accept or refuse cookies. As how you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser's help menu for more information.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>How often will you update this Cookie Policy?</h1>

                    <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>

                    <h1 className='uppercase text-xl font-bold text-tangerine'>Where can I get further information?</h1>

                    <p>If you have any questions about our use of cookies or other technologies, please email us at admin@litudian.com</p>

                </div>

            </Layout>

        )

    }

};

const mapStateToProps = (state) => {

    return {

        

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({  }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
