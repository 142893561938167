import React, { useState, useRef } from "react";
import Modal from "../components/Modal";
import { Dialog } from "@headlessui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import Label from "../components/Label";
import Input from "../components/Input";
import InputError from "../components/InputError";
import Button from "../components/Button";
import { toast } from "react-toastify";
import instance from "../utils/instance";

const schema = Yup.object().shape({
  order_number: Yup.string()
    .min(3)
    .required("Order Number is a required field"),
});

function Index(props) {
  // Props
  const { isOpen, setIsOpen } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Ref
  const ref = useRef(null);

  // Functions
  const validate_bid = async (values) => {
    try {
      if (ref?.current) ref.current.innerHTML = "";
      setLoading(true);
      let response = await instance.get(
        `bid_validation/${values?.order_number}`
      );
      if (response?.data) {
        setLoading(false);
        if (response.data?.length === 0) {
          if (ref?.current) return (ref.current.innerHTML =
            "This order number could not be found");
        }
        setIsOpen(false);
        let bid = response?.data[0] || null;
        if (bid) return window.location.href = `/items/${bid?.id}`;
        
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "Could not complete your request at this time. Please try again later !",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="space-y-8 max-w-xl">
      <div className="flex flex-row items-center justify-between w-full">
        <Dialog.Title
          as="h3"
          className="text-lg font-bold leading-6 text-gray-900"
        >
          Track Order
        </Dialog.Title>
        <button onClick={() => setIsOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          order_number: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          validate_bid(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              <Label htmlFor="order_number">Order Number</Label>

              <Input
                id="order_number"
                type="text"
                value={values.order_number}
                className="block mt-1 w-full bg-gray-200 min-h-[40px] px-3"
                onChange={handleChange}
                autoFocus
                placeholder="e.g 1Y5HXET"
              />

              <InputError messages={[errors.order_number]} className="mt-2" />
              <p ref={ref} className={`text-red-500 text-sm`}></p>
            </div>
            <div className="flex flex-col items-center justify-center mt-8 space-y-2">
              <Button
                className="w-full flex flex-col items-center justify-center py-3 bg-tangerine"
                type="submit"
              >
                {loading ? "Loading..." : "Validate"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default Index;
