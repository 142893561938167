import React, { useEffect, useState, useMemo } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function MyComponent(props) {
  const { setLng, setLat, lat, lng } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBw-W7Y4TWh3XOG6k7wWElqS92soODzxJ8",
  });

  const center = useMemo(() => {
    let obj = {
      // lat: -1,
      // lng: 36,
    };
    if (lat) obj.lat = lat;
    if (lng) obj.lng = lng;
    return obj;
  }, [lat, lng]);

  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    setZoom(13);
  }, [lat, lng]);

  if (!isLoaded) return null;

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
      >
        <MarkerF
          position={center}
          draggable
          onDrag={(e) => {
            setLat(e.latLng.lat());
            setLng(e.latLng.lng());
          }}
        />
      </GoogleMap>
    </>
  );
}

export default MyComponent;
